import { RouteComponentProps, navigate } from "@reach/router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Flex } from "rebass";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getActiveEntryInLeague,
  getCode,
} from "../../core/store/leagues/reducers";
import {
  fetchLeagueCode,
  joinPrivateLeague,
  setCode,
} from "../../core/store/leagues/thunks";
import {
  IJoinPrivateLeagueData,
  ILeague,
} from "../../core/store/leagues/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import Button from "../Button";
import ButtonLink from "../ButtonLink";
import Title from "../Title";

const StyledHeader = styled.div`
  padding-left: ${({ theme }) => theme.space[2]};
  padding-right: ${({ theme }) => theme.space[2]};
`;

const ButtonWrapper = styled.div``;

type OwnProps = RouteComponentProps<{
  type: string;
  league: ILeague | null;
  leagueNumber: number;
}>;

interface IPropsFromState {
  entryInLeague: any | null;
  leagueNumber: number;
  code: string | null;
  player: IPlayer | null;
}

interface IPropsFromDispatch {
  fetchCode: (leagueId: number) => void;
  joinPrivateLeague: (data: IJoinPrivateLeagueData) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

const StandingsHeading: React.FC<Props> = ({
  code,
  player,
  fetchCode,
  league,
  leagueNumber,
  entryInLeague,
}) => {
  const { t } = useTranslation();
  const isAdmin =
    entryInLeague &&
    entryInLeague.league &&
    entryInLeague.league.entry_can_admin;
  const isEntryInLeague = entryInLeague && entryInLeague.league;
  const reduxDispatch = useDispatch<ThunkDispatch>();

  useEffect(() => {
    if (
      !isEntryInLeague &&
      league &&
      league.code_privacy === "a" &&
      leagueNumber
    ) {
      fetchCode(leagueNumber);
    }
  }, [league, isAdmin, isEntryInLeague, leagueNumber, fetchCode]);

  let showInviteBtn = false;
  let showJoinBtn = false;

  if (league && !league.closed) {
    if (isAdmin) {
      showInviteBtn = true;
    } else {
      if (league) {
        if (isEntryInLeague) {
          if (league.code_privacy === "a") {
            showInviteBtn = true;
          }
          if (league.code_privacy === "l") {
            showInviteBtn = true;
          }
        }

        if (!isEntryInLeague) {
          if (league.code_privacy === "a") {
            showJoinBtn = true;
          }
        }
      }
    }
  }

  return (
    <StyledHeader>
      <Flex alignItems="center" justifyContent="space-between">
        <Title>{league && league.name}</Title>
        <ButtonWrapper>
          {showInviteBtn && (
            <ButtonLink
              variant="secondary"
              to={`/leagues/${entryInLeague.league.id}/invite`}
            >
              {t(
                "standingsHeading.inviteButton",
                "Invite people to join this league"
              )}
            </ButtonLink>
          )}

          {code && showJoinBtn && (
            <Button
              onClick={() =>
                player && player.entry
                  ? reduxDispatch(setCode(code))
                  : navigate(`/leagues/auto-join/${code}`)
              }
            >
              {t("standingsHeading.joinButton", "Join this league")}
            </Button>
          )}
        </ButtonWrapper>
      </Flex>
    </StyledHeader>
  );
};

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const leagueNumber = ownProps.leagueNumber || 0;
  const entryInLeague = getActiveEntryInLeague(
    state,
    leagueNumber,
    ownProps.type === "classic" ? "classic" : "h2h"
  );

  return {
    leagueNumber,
    entryInLeague: entryInLeague,
    player: getPlayerData(state),
    code: leagueNumber ? getCode(state, leagueNumber) : null,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchCode: (leagueNumber: number) => dispatch(fetchLeagueCode(leagueNumber)),
  joinPrivateLeague: (data: IJoinPrivateLeagueData) => {
    dispatch(joinPrivateLeague(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StandingsHeading);
