import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import image from "../img/prizes-hero@1x.jpg";
import image2x from "../img/prizes-hero@2x.jpg";
import Copy from "./Copy";
import Hero from "./Hero";
import { Main, Wrapper } from "./Layout";
import SubHeading from "./SubHeading";

const Prizes: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Hero
          title={t("prizes.title", "Prizes")}
          image={image}
          image2x={image2x}
        ></Hero>
        <Copy>
          <Box mb="3rem">
            <SubHeading>{t("prizes.season.title", "Season")}</SubHeading>
            <h4>
              {t("prizes.season.firstPrize.title_23", "Main season winner")}
            </h4>
            <ul>
              <li>
                {t("prizes.season.firstPrize.item1_23", "Samsung 85 inch TV")}
              </li>
              <li>
                {t("prizes.season.firstPrize.item2_23", "Samsung tablet")}
              </li>
              <li>
                {t(
                  "prizes.season.firstPrize.item3_23",
                  "Annual subscription to TV 2 Play (value NOK 399/month)"
                )}
              </li>
              <li>{t("prizes.season.firstPrize.item4_23", "FC 24")}</li>
            </ul>
            <h4>
              {t("prizes.season.secondPrize.title_23", "Second place season")}
            </h4>
            <ul>
              <li>
                {t("prizes.season.secondPrize.item1_23", "Samsung tablet")}
              </li>
              <li>
                {t("prizes.season.secondPrize.item2_23", "Samsung mobile")}
              </li>
              <li>
                {t(
                  "prizes.season.secondPrize.item3_23",
                  "Annual subscription to TV 2 Play (value NOK 399/month)"
                )}
              </li>
              <li>{t("prizes.season.secondPrize.item4_23", "FC 24")}</li>
            </ul>
            <h4>
              {t("prizes.season.thirdPrize.title_23", "Third place season")}
            </h4>
            <ul>
              <li>
                {t("prizes.season.thirdPrize.item1_23", "Samsung tablet")}
              </li>
              <li>
                {t(
                  "prizes.season.thirdPrize.item2_23",
                  "Annual subscription to TV 2 Play (value NOK 399/month)"
                )}
              </li>
              <li>{t("prizes.season.thirdPrize.item3_23", "FC 24")}</li>
            </ul>
          </Box>

          <Box mb="3rem">
            <SubHeading>
              {t(
                "prizes.cup.title_23",
                "The road to UEFA European Football Championship - from round 6 to 11."
              )}
            </SubHeading>
            <h4>
              {t(
                "prizes.cup.subHeading_23",
                "The main prize (drawn randomly among all participants in the game)"
              )}
            </h4>
            <ul>
              <li>
                {t(
                  "prizes.cup.item1_23",
                  "Trip to UEFA European Football Championship with plane, hotel and tickets to the match between Spain and Italy.  The flight is from Gardermoen in Norway"
                )}
              </li>
            </ul>
            <h4>{t("prizes.cup.subheading2_23", "Phase winner")}</h4>
            <ul>
              <li>{t("prizes.cup.item2_23", "Samsung product")}</li>
              <li>
                {t(
                  "prizes.cup.item3_23",
                  "Annual subscription to TV 2 Play (value NOK 399/month)"
                )}
              </li>
            </ul>
          </Box>

          <Box mb="3rem">
            <SubHeading>
              {t("prizes.monthly.title", "Monthly (Phases)")}
            </SubHeading>
            <h4>{t("prizes.monthly.subHeading", "Manager of the month")}</h4>
            <ul>
              <li>{t("prizes.monthly.item1_23", "Samsung products")}</li>
              <li>
                {t(
                  "prizes.monthly.item2_23",
                  "Annual subscription to TV 2 Play (value NOK 399/month)"
                )}
              </li>
              <li>{t("prizes.monthly.item3_23", "FC 24")}</li>
            </ul>
          </Box>

          <Box mb="3rem">
            <p>
              {t(
                "prizes.note",
                "NOTE: If there are multiple participants with the same score among the mentioned prizes above, the winner will be determined by a random draw."
              )}
            </p>
          </Box>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default Prizes;
