import orderBy from "lodash/orderBy";
import { size } from "polished";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { getElements } from "../../core/store/elements/reducers";
import { IElement } from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { ReactComponent as ArrowLeft } from "../../img/icons/transfer-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../img/icons/transfer-arrow-right.svg";
import BoldLink from "../BoldLink";
import ElementDialogButton from "../ElementDialogButton";
import {
  ElementRow,
  ElementTable,
  PrimaryCell,
  PrimaryCellLined,
} from "../ElementTable";
import SubHeading from "../SubHeading";
import { Ellipsis } from "../Utils";
import StatusPanel from "./StatusPanel";

const BodyArrowIn = styled(ArrowRight)`
  ${size(12)};
  fill: #2fab2f;
`;

const BodyArrowOut = styled(ArrowLeft)`
  ${size(12)};
  fill: #de0f0f;
`;

const TransfersTable = styled(ElementTable)`
  th:not(:nth-child(4)) {
    text-align: center;
  }
`;

const StatusCol = styled.th`
  width: 12%;
`;

const ArrowCol = styled.th`
  width: 10%;
`;

const PosCol = styled.th`
  width: 14%;
  text-align: center;
`;

const ClubCol = styled.th`
  width: 16%;
`;

const PlayerCol = styled.th`
  width: 32%;
`;

const TransfersCol = styled.th`
  width: 16%;
`;

interface IPropsFromState {
  elements: IElement[];
  elementTypesById: IElementTypesById;
  teamsById: ITeamsById;
}

interface IOwnProps {
  isOut?: boolean;
}

type Props = IPropsFromState & IOwnProps;

const TransfersPanel: React.FC<Props> = ({
  elements,
  elementTypesById,
  isOut,
  teamsById,
}) => {
  const { t } = useTranslation();
  const stat = isOut ? "transfers_out_event" : "transfers_in_event";
  const transferStatus = isOut
    ? t("transfersPanel.out", "Out")
    : t("transfersPanel.in", "In");
  return (
    <StatusPanel>
      <StatusPanel.Header
        link={
          <BoldLink to={`/statistics/${stat}`}>
            {t("transfersPanel.postitionShort", "Pos")}
          </BoldLink>
        }
      >
        <SubHeading>
          {t("transfersPanel.heading", "Top Transfers {{ inOut }}", {
            inOut: transferStatus,
          })}
        </SubHeading>
      </StatusPanel.Header>
      <StatusPanel.Body>
        <TransfersTable>
          <thead>
            <tr>
              <StatusCol>&nbsp;</StatusCol>
              <ArrowCol>&nbsp;</ArrowCol>
              <PosCol>{t("transfersPanel.postitionShort", "Pos")}</PosCol>
              <PlayerCol>{t("transfersPanel.player", "Player")}</PlayerCol>
              <ClubCol>{t("transfersPanel.club", "Club")}</ClubCol>
              <TransfersCol>&nbsp;</TransfersCol>
            </tr>
          </thead>
          <tbody>
            {orderBy(elements, [stat], ["desc"])
              .slice(0, 5)
              .map((e) => (
                <ElementRow key={e.id}>
                  <td>
                    <ElementDialogButton elementId={e.id} variant="list" />
                  </td>
                  <PrimaryCell>
                    {isOut ? <BodyArrowOut /> : <BodyArrowIn />}
                  </PrimaryCell>
                  <PrimaryCell>
                    {elementTypesById[e.element_type].singular_name_short}
                  </PrimaryCell>
                  <td>
                    <Ellipsis>{e.web_name}</Ellipsis>
                  </td>
                  <PrimaryCell>{teamsById[e.team].short_name}</PrimaryCell>
                  <PrimaryCellLined>{e[stat]}</PrimaryCellLined>
                </ElementRow>
              ))}
          </tbody>
        </TransfersTable>
      </StatusPanel.Body>
    </StatusPanel>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elements: getElements(state),
  elementTypesById: getElementTypesById(state),
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(TransfersPanel);
