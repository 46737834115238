import { Link } from "@reach/router";
import { hiDPI, size } from "polished";
import * as React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Logo } from "../img/logos/es-fantasy-logo.svg";
import { ReactComponent as GBFlag } from "../img/flags/gb.svg";
import { ReactComponent as NOFlag } from "../img/flags/no.svg";
import Navigation from "./nav/Navigation";
import EliteHeaderLg1x from "../img/elite-header-lg.jpg";
import EliteHeaderLg2x from "../img/elite-header-lg@2x.jpg";
import EliteHeaderSm1x from "../img/elite-header-sm.jpg";
import EliteHeaderSm2x from "../img/elite-header-sm@2x.jpg";

const StyledLogo = styled(Logo)`
  ${size(38, 173)}
`;

const GameHeaderOuter = styled.div`
  min-height: 130px;
  background-image: url(${EliteHeaderSm1x});

  ${hiDPI(2)} {
    background-image: url(${EliteHeaderSm2x});
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 34%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    background-image: url(${EliteHeaderLg1x});

    ${hiDPI(2)} {
      background-image: url(${EliteHeaderLg2x});
    }
  }
`;

const GameHeaderInner = styled.div`
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => `0 ${theme.space[4]} ${theme.space[4]}`};
`;

const GameHeaderUpper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GameHeading = styled.h1`
  margin: ${({ theme }) => theme.space[6]} 0;
  line-height: 0.8;
`;

const GameHeadingLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.blueDarker1};
  font-family: ${(props) => props.theme.fonts.impact};
  font-size: 43px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 48px;
  }
`;

const HeadingWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1 0 75%;
  }
`;

const FlagsWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 32px;
`;

const FlagList = styled.ul`
  padding: 8px 0;
  font-size: 0;
  list-style-type: none;
`;

const FlagItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  padding: 0 ${({ theme }) => theme.space[2]};
  line-height: 1;

  :not(:last-child) {
    border-right: 1px solid #cac5dd;
  }
`;

const FlagLink = styled.a`
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
`;

const StyledNOFlag = styled(NOFlag)`
  ${size(20, 20)}
`;

const StyledGBFlag = styled(GBFlag)`
  ${size(20, 20)}
`;

const GameHeader: React.FC = () => {
  return (
    <GameHeaderOuter>
      <GameHeaderInner>
        <GameHeaderUpper>
          <HeadingWrap>
            <GameHeading>
              <GameHeadingLink to="/">
                <StyledLogo />
              </GameHeadingLink>
            </GameHeading>
          </HeadingWrap>
          <FlagsWrap>
            <FlagList>
              <FlagItem>
                <FlagLink href="https://fantasy.eliteserien.no">
                  <StyledNOFlag />
                </FlagLink>
              </FlagItem>
              <FlagItem>
                <FlagLink href="https://en.fantasy.eliteserien.no">
                  <StyledGBFlag />
                </FlagLink>
              </FlagItem>
            </FlagList>
          </FlagsWrap>
        </GameHeaderUpper>
        <Navigation />
      </GameHeaderInner>
    </GameHeaderOuter>
  );
};

export default GameHeader;
