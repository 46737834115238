import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import { RootState } from "../../core/store";
import {
  getEntry,
  getPrivateClassicCupLeaguesForEntry,
  getPublicClassicCupLeaguesForEntry,
  getSystemClassicCupLeaguesForEntry,
} from "../../core/store/entries/reducers";
import { getPlayerData } from "../../core/store/player/reducers";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import { Main, Secondary, Wrapper } from "../Layout";
import Title from "../Title";
import CupTypeTable from "./CupTypeTable";
import LeaguesNav from "./LeaguesNav";
import {
  getBroadcasterLeagues,
  getFilteredSystemClassicLeagues,
} from "./utils";

const StyledMain = styled(Main)`
  min-width: 100%;
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${({ theme }) => `${theme.space[8]} ${theme.space[4]}`};
  }
`;

const LeaguesNavWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-right: 20%;
    margin-left: 20%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    margin-right: 30%;
    margin-left: 30%;
  }
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.black};
`;

const MyCups: React.FC = () => {
  const player = useSelector(getPlayerData);
  const entry = useSelector((state: RootState) =>
    player?.entry ? getEntry(state, player.entry) : null
  );
  const privateClassicLeagues = useSelector((state: RootState) =>
    entry ? getPrivateClassicCupLeaguesForEntry(state, entry.id) : []
  );
  const publicClassicLeagues = useSelector((state: RootState) =>
    entry ? getPublicClassicCupLeaguesForEntry(state, entry.id) : []
  );
  const systemClassicLeagues = useSelector((state: RootState) =>
    entry ? getSystemClassicCupLeaguesForEntry(state, entry.id) : []
  );
  const { t } = useTranslation();

  if (!entry) {
    return null;
  }

  const hasCupLeagues =
    privateClassicLeagues.length > 0 ||
    publicClassicLeagues.length > 0 ||
    systemClassicLeagues.length > 0;

  const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
  const filteredSystemClassicLeagues = getFilteredSystemClassicLeagues(
    systemClassicLeagues,
    broadcasterLeagues
  );

  return (
    <>
      <Wrapper>
        <Box flex={1} pt={4} mx={2}>
          <Title>
            {t("myCups.title", "Leagues & Cups - {{ entryName }}", {
              entryName: entry.name,
            })}
          </Title>
          <LeaguesNavWrap>
            <LeaguesNav />
          </LeaguesNavWrap>
        </Box>
      </Wrapper>
      <Wrapper>
        <StyledMain>
          {hasCupLeagues ? (
            <>
              {privateClassicLeagues.length > 0 && (
                <Box pb={4}>
                  <CupTypeTable
                    leagues={privateClassicLeagues}
                    title={t("myCups.privateCups", "League cups")}
                  />
                </Box>
              )}
              {publicClassicLeagues.length > 0 && (
                <Box pb={4}>
                  <CupTypeTable
                    leagues={publicClassicLeagues}
                    title={t("myCups.publicCups", "Public cups")}
                  />
                </Box>
              )}
              {filteredSystemClassicLeagues.length > 0 && (
                <Box pb={4}>
                  <CupTypeTable
                    leagues={filteredSystemClassicLeagues}
                    title={t("myCups.globalCups", "Global cups")}
                  />
                </Box>
              )}
            </>
          ) : (
            <Copy>
              <p>
                {t("myCups.noCups", "None of your leagues have a cup yet.")}
              </p>
              <ButtonLink to="/leagues/create-join">
                <StyledSpan>
                  {t("myCups.button", "Create and Join New Leagues & Cups")}
                </StyledSpan>
              </ButtonLink>
            </Copy>
          )}
        </StyledMain>
        <Secondary />
      </Wrapper>
    </>
  );
};

export default MyCups;
