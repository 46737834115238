// my-theme.ts
import { em } from "polished";
import { DefaultTheme } from "styled-components";

const blue = "#00a8e1";
const blueblack = "#00263d";
const fantasy = "#00ff87";
const primary = "#e9e7f2";
const yellow = "#ebff00";
const red = "#d22a5d";
const green = "#03b155";
const black = "#100118";
const lightblack = "#726b85";

const theme: DefaultTheme = {
  breakpoints: [
    em("400px"),
    em("610px"),
    em("700px"),
    em("900px"),
    em("1024px"),
    em("1220px"),
  ],
  colors: {
    black: black,
    blackLighter: lightblack,
    difficulties: {
      1: {
        bg: "#257d5a",
        color: "white",
      },
      2: {
        bg: "#00ff86",
        color: "black",
      },
      3: {
        bg: "#ebebe4",
        color: "black",
      },
      4: {
        bg: "#ff005a",
        color: "white",
      },
      5: {
        bg: "#861d46",
        color: "white",
      },
    },
    elementStatus: {
      0: {
        bg: "#c0020d",
        color: "white",
      },
      25: {
        bg: "#d44401",
        color: "white",
      },
      50: {
        bg: "#ffab1b",
        color: blueblack,
      },
      75: {
        bg: "#ffe65b",
        color: blueblack,
      },
    },
    error: "#df2f2f",
    fantasy,
    pink: "#ff2882",
    pinkLighter: "#ffb1c9",
    primary,
    primaryDarker: "#cac5dd",
    grey: "#f2f2f2",
    greyDarker1: "#e5e5e5",
    greyDarker2: "#f0f0f8",
    greyDarker3: "#6e6e6e",
    greyDarker4: "#d9d8ed",
    greyDarker5: "#f2f9fa",
    blue,
    blueDarker1: "#3069a5",
    blueDarker2: "#004986",
    blueDarker3: "#14253c",
    blueDarker4: "#114387",
    red: red,
    redDarker: "#ab224f",
    green: green,
    social: {
      facebook: {
        bg: "#3b5998",
        color: "white",
      },
      twitter: {
        bg: "#00aced",
        color: "white",
      },
      google: {
        bg: "white",
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    success: "#14af4f",
    text: lightblack,
    white: "#fff",
    whiteDarker: "#f8f8fc",
    whiteDarker2: "#fafafa",
    yellow,
  },
  fontSizes: [
    "1.2rem",
    "1.3rem",
    "1.4rem",
    "1.6rem",
    "1.8rem",
    "2rem",
    "4rem",
    "4.8rem",
  ],
  fonts: {
    base: '"TV 2 Text Light", Helvetica, Arial, sans-serif',
    baseSemiBold: '"TV 2 Text Semi Bold", Helvetica, Arial, sans-serif',
    baseBold: '"TV 2 Text Bold", Helvetica, Arial, sans-serif',
    impact: '"TV 2 Display Semi Bold", Impact, sans-serif',
    impactMedium: '"TV 2 Display Medium", Impact, sans-serif',
  },
  radii: ["6px"],
  space: [
    "0",
    "0.5rem",
    "1rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3rem",
    "3.5rem",
    "4rem",
  ],
  zIndex: {
    clientMenu: 7000,
    dialog: 9000,
    menu: 6000,
    overlay: 8000,
    select: {
      select: 5000,
      wrap: 4000,
    },
  },
};

export default theme;
