import * as React from "react";
import styled, { css } from "styled-components/macro";

const StyledScoreboardPanel = styled.div`
  padding: ${(props) => props.theme.space[2]} 0;
  text-align: center;
`;

const Heading = styled.h3`
  margin: 0;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.base};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 5px;
`;

const Value = styled.div<IValue>`
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1;
  font-family: ${({ theme }) => theme.fonts.impact};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 24px;
  }

  ${(props) =>
    props.isError &&
    css`
      color: ${props.theme.colors.red};
    `}

  ${(props) =>
    props.isSuccess &&
    css`
      color: ${props.theme.colors.green};
    `}
`;

interface IScoreboardPanel {
  heading: string;
  value: string;
}

interface IValue {
  isError?: boolean;
  isSuccess?: boolean;
}

type Props = IScoreboardPanel & IValue;

export const ScoreboardPanel: React.FC<Props> = ({
  heading,
  isError,
  isSuccess,
  value,
}) => (
  <StyledScoreboardPanel>
    <Heading>{heading}</Heading>
    <Value isError={isError} isSuccess={isSuccess}>
      {value}
    </Value>
  </StyledScoreboardPanel>
);
