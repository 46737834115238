import { Link } from "@reach/router";
import React from "react";
import styled from "styled-components";
import { INavLink } from "../../core/hooks/types";
import "./ListItem.css";

const LIStyled = styled.li`
  list-style-type: none;
  display: inline-block;
  margin: 0 8px 0 0;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.primary};

  :last-child a {
    border: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.redDarker};
    border-radius: 6px;
  }
  &:hover a {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ListItem = React.forwardRef<HTMLLIElement, INavLink>(
  ({ useRouter, text, href, rel, indicateExternal, target }, ref) => {
    return (
      <LIStyled ref={ref}>
        {useRouter ? (
          <Link
            to={href}
            getProps={({ isCurrent, isPartiallyCurrent }) => {
              return {
                className: `ism-nav__tab ${
                  href === "/"
                    ? isCurrent
                      ? "active"
                      : ""
                    : isPartiallyCurrent
                    ? "active"
                    : ""
                }`,
              };
            }}
          >
            {text}
          </Link>
        ) : (
          <a href={href} className="ism-nav__tab" rel={rel} target={target}>
            <span>{text}</span>
          </a>
        )}
      </LIStyled>
    );
  }
);

export default ListItem;
