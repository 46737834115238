import { Link } from "@reach/router";
import React from "react";
import styled, { css } from "styled-components/macro";

const NavList = styled.ul`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.space[2]};
  padding-left: 0;
`;

const NavItem = styled.li`
  flex: 1;
  overflow: hidden;
  list-style-type: none;
  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

// TODO - This stops isCurrent being passed to Link but doesn't have
// any type checking
const NavLink = styled(({ isCurrent, ...rest }) => <Link {...rest} />)`
  display: block;
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.blackLighter};
  font-family: ${({ theme }) => theme.fonts.impactMedium};
  font-size: 1.6rem;
  text-decoration: none;
  overflow: hidden;
  border: none;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDarker};
  }

  ${(props) =>
    props.isCurrent &&
    css`
      background-color: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.white};
      border-radius: ${({ theme }) => theme.radii[0]};
      padding: 1.2rem 0;

      &:hover {
        background-color: ${({ theme }) => theme.colors.redDarker};
      }
    `}
`;

interface ILinks {
  href: string;
  text: string;
}

interface ISubNav {
  links: ILinks[];
}

const SubNav: React.FC<ISubNav> = ({ links }) => (
  <nav>
    <NavList>
      {links.map((l) => (
        <NavItem key={l.href}>
          <NavLink to={l.href} isCurrent={window.location.pathname === l.href}>
            {l.text}
          </NavLink>
        </NavItem>
      ))}
    </NavList>
  </nav>
);

export default SubNav;
