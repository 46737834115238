import * as React from "react";

const tvPath = `${process.env.PUBLIC_URL}/dist/img/tv_channels`;
const prefix = "tv_";

interface IProps {
  sizes: string;
  channel: string;
}

const Tv: React.FC<IProps> = ({ sizes, channel }) => {
  const path = `${tvPath}/${prefix}${channel}`;

  return (
    <picture>
      <source
        type="image/webp"
        srcSet={`
            ${path}-70.webp 70w,
            ${path}-140.webp 140w,
            `}
        sizes={sizes}
      />
      <img
        src={`${path}-70.png`}
        srcSet={`
            ${path}-70.png 70w,
            ${path}-140.png 140w,
            `}
        sizes={sizes}
        alt={channel}
      />
    </picture>
  );
};

export default Tv;
