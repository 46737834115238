import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "../../../core/store";
import { banLeagueEntry } from "../../../core/store/leagues/thunks";
import {
  IBanEntryData,
  IPrivateLeagueAdmin,
} from "../../../core/store/leagues/types";
import Button from "../../Button";
import { FieldWrap } from "../../FieldRenderers";
import SubHeading from "../../SubHeading";
import EntrySelector from "./EntrySelector";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

interface IPropsFromDispatch {
  banLeagueEntry: (leagueId: number, data: IBanEntryData) => void;
}

type Props = IOwnProps & IPropsFromDispatch & WithTranslation;

interface IState {
  entry: number;
}

class AddBan extends React.Component<Props, IState> {
  public state: IState = {
    entry: 0,
  };

  public handleEntryChange = (entryId: number) => {
    this.setState({ entry: entryId });
  };

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.banLeagueEntry(this.props.league.id, this.apiDataFromState());
  };

  public apiDataFromState = (): IBanEntryData => ({
    entry: this.state.entry,
  });

  public render() {
    const disabled = !this.state.entry;
    const { league, t } = this.props;
    return (
      <>
        <SubHeading>{t("addBan.title", "Add league suspensions")}</SubHeading>
        <p>
          {t(
            "addBan.description",
            "Search for the player you wish to suspend from this league. Once the player has been suspended they will be unable to rejoin the league again."
          )}
        </p>
        <form onSubmit={this.handleSubmit}>
          <FieldWrap>
            <EntrySelector
              handleChange={this.handleEntryChange}
              leagueId={league.id}
              excluded={[league.admin_entry]}
            />
          </FieldWrap>
          <FieldWrap>
            <Button type="submit" disabled={disabled}>
              {t("addBan.suspendPlayer", "Suspend player")}
            </Button>
          </FieldWrap>
        </form>
      </>
    );
  }
}
export { AddBan as AddBanTest };

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  banLeagueEntry: (leagueId: number, data: IBanEntryData) =>
    dispatch(banLeagueEntry(leagueId, data)),
});

export default withTranslation()(connect(null, mapDispatchToProps)(AddBan));
