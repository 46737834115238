import { ellipsis, hideVisually } from "polished";
import styled from "styled-components/macro";

export const Ellipsis = styled.div`
  ${ellipsis()}
`;

export const UnstyledButton = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;
`;

export const VisuallyHidden = styled.span`
  ${hideVisually()}
`;
