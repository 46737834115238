import { hideVisually } from "polished";
import * as React from "react";
import styled, { css } from "styled-components/macro";

const Button = styled.button<IStyledProps>`
  height: 36px;
  width: 36px;
  padding: 10px;
  border: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.blackLighter};
    `}

  ${(props) =>
    props.isSmall &&
    css`
      height: 26px;
      width: 26px;
      padding: 5px;
    `}
`;

const HiddenLabel = styled.span`
  ${hideVisually}
`;

interface IStyledProps {
  disabled?: boolean;
  isSmall?: boolean;
}

interface IProps {
  actionMe: () => void;
  children: React.ReactNode;
  label: string;
}

type Props = IProps & IStyledProps;

const PaginatorButton: React.FC<Props> = ({
  actionMe,
  children,
  disabled,
  isSmall,
  label,
}) => (
  <Button
    onClick={actionMe}
    type="button"
    disabled={disabled}
    isSmall={isSmall}
  >
    {children}
    <HiddenLabel>{label}</HiddenLabel>
  </Button>
);

export default PaginatorButton;
