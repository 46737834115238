import styled from "styled-components/macro";

const LinkButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blueDarker3};
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export default LinkButton;
