import { TFunction } from "i18next";

export type ChipName = "frush" | "2capt" | "rich" | "wildcard";

interface IChipDetails {
  description: string;
  name: string;
}

const allChipDetails = (t: TFunction): Record<ChipName, IChipDetails> => ({
  frush: {
    name: t("chips.frush.name", "Attack! Attack!"),
    description: t(
      "chips.frush.description",
      "All forwards get double points."
    ),
  },
  "2capt": {
    name: t("chips.2capt.name", "Two Captains"),
    description: t(
      "chips.2capt.description",
      "Both your Captain and Vice Captain score double points."
    ),
  },
  rich: {
    name: t("chips.rich.name", "Rich Uncle"),
    description: t(
      "chips.rich.description",
      "Make unlimited free transfers with an unlimited budget for a single round. Next round your team will be set back to how it was before using the chip."
    ),
  },
  wildcard: {
    name: t("chips.wildcard.name", "Wildcard"),
    description: t(
      "chips.wildcard.description",
      "Make unlimited free transfers."
    ),
  },
});

export const getChipName = (name: ChipName, t: TFunction) =>
  allChipDetails(t)[name].name;

export const getChipDetails = (name: ChipName, t: TFunction) =>
  allChipDetails(t)[name];

export const getAllChipDetails = (t: TFunction) => allChipDetails(t);
