import { size } from "polished";
import styled, { css } from "styled-components/macro";
import { ReactComponent as Captain } from "../../img/icons/captain.svg";
import { ReactComponent as ViceCaptain } from "../../img/icons/vice-captain.svg";

interface IStyledCaptainProps {
  variant?: string;
}

export const StyledCaptain = styled(Captain)<IStyledCaptainProps>`
  ${size(14)};
  color: white;

  ${(props) =>
    props.variant === "3xc" &&
    css`
      color: black;
      fill: white;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
    right: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
    right: 20px;
  }
`;

export const StyledViceCaptain = styled(ViceCaptain)<IStyledCaptainProps>`
  ${size(14)};
  color: white;

  ${(props) =>
    props.variant === "3xc" &&
    css`
      color: black;
      fill: white;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
    right: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
    right: 20px;
  }
`;
