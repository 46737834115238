export type SITE_IDS = "main_nb" | "main_en" | "tv2";

const sites: Record<string, SITE_IDS> = {
  "dev.fantasy.eliteserien.no": "main_nb",
  "en.dev.fantasy.eliteserien.no": "main_en",
  "eliteserien.staging.ismgames.com": "main_nb",
  "en.eliteserien.staging.ismgames.com": "main_en",
  "fantasy.eliteserien.no": "main_nb",
  "en.fantasy.eliteserien.no": "main_en",
  "fantasy.tv2.no": "tv2",
  "dev.fantasy.tv2.no": "tv2",
  "tv2-eliteserien.staging.ismgames.com": "tv2",
};

export const getSite = () => sites[window.location.hostname] || "";
