import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getURL } from "../core/store/urls/reducers";
import { fetchURL } from "../core/store/urls/thunks";
import LogoBar from "./LogoBar";
import SubHeading from "./SubHeading";

const StyledSubHeading = styled(SubHeading)`
  font-size: 1.6rem;
  margin: auto 0;
`;

const StyledLink = styled.a`
  color: #000;
  text-decoration: none;
`;

const Article = styled.article`
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueDarker4};

    ${StyledLink} {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 25%;
  }
`;

const ArticleFeatureImg = styled.img`
  width: 100%;
`;
const StyledProvider = styled.section`
  border: 1px solid #e5e5e5;
  margin-bottom: ${({ theme }) => theme.space[4]};
`;

const ProviderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface IGoals {
  description: string;
  thumbnail_url: string;
  frontend_url: string;
  id: string;
}
type Props = RouteComponentProps;

const GoalVideos: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [providerData, setProviderData] = useState<IGoals[]>([]);
  const providerUrl = encodeURIComponent(
    'https://api.forzasys.com/eliteserien/playlist?filters=event&tags={"action":"goal"}&orderby=date&count=4'
  );
  // Fetch JSON
  const reduxDispatch = useDispatch<ThunkDispatch>();
  useEffect(() => {
    reduxDispatch(fetchURL(providerUrl));
  }, [reduxDispatch, providerUrl]);
  // Get JSON content, will be null until we have some
  const content = useSelector((state: RootState) => getURL(state, providerUrl));
  // Parse JSON data
  useEffect(() => {
    const parseContent = async () => {
      try {
        const feed = await JSON.parse(content.text);
        if (feed && feed.playlists) {
          setProviderData(feed.playlists.splice(0, 4));
        }
      } catch (e) {}
    };
    if (content) {
      parseContent();
    }
  }, [content]);
  if (!providerData) {
    return null;
  }
  return (
    <StyledProvider>
      <LogoBar justifyContent="center">
        <StyledSubHeading variant="dark">
          {t("latestGoals.title", "Latest goals")}
        </StyledSubHeading>
      </LogoBar>

      <ProviderContent>
        {providerData.map((item: IGoals) => (
          <Article key={item.id}>
            <StyledLink
              href={item.frontend_url}
              target="_blank"
              rel={item.description}
            >
              <ArticleFeatureImg
                src={item.thumbnail_url}
                alt={item.description}
              />
              <h4>{item.description}</h4>
            </StyledLink>
          </Article>
        ))}
      </ProviderContent>
    </StyledProvider>
  );
};

export default GoalVideos;
