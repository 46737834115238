import * as React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementsById } from "../core/store/elements/reducers";
import { showElementSummary } from "../core/store/elements/thunks";
import { IElementsById } from "../core/store/elements/types";
import ElementStatus from "./ElementStatus";
import { useTranslation } from "react-i18next";

const StyledElementDialogButton = styled.button<IStyledProps>`
  display: block;
  width: 100%;
  border: 0;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;
  padding: 1.3rem 0.8rem;

  ${({ variant }) =>
    variant === "pitch" &&
    css`
      padding: 0;
      background-color: transparent;
    `}
`;

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

interface IPropsFromState {
  elementsById: IElementsById;
}

interface IOwnProps {
  elementId: number;
}

interface IStyledProps {
  variant?: "list" | "pitch";
}

type Props = IPropsFromDispatch & IPropsFromState & IOwnProps & IStyledProps;

const ElementDialogButton: React.FC<Props> = ({
  elementId,
  elementsById,
  showElementDialog,
  variant = "pitch",
}) => {
  const { t } = useTranslation();
  return (
    <StyledElementDialogButton
      onClick={() => showElementDialog(elementId)}
      title={t("viewInfoTitle", "View player information")}
      variant={variant}
    >
      <ElementStatus element={elementsById[elementId]} />
    </StyledElementDialogButton>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ElementDialogButton);
