import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementStats } from "../../core/store/element-stats/reducers";
import { IElementStat } from "../../core/store/element-stats/types";
import { getIncludedStats } from "../../utils/stats";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import {
  IElement,
  IElementEventStats,
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementRow } from "../ElementTable";
import Table from "../Table";
import { StyledCaptain, StyledViceCaptain } from "./TableCaptains";
import Panel from "../Panel";

const ScrollTable = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StatsTable = styled(Table)`
  white-space: nowrap;
`;

interface IEntryEventRowProps {
  chipName: string | null;
  data: IElementEventStats;
  pick: IPickLight;
  element: IElement;
  elementTypesById: IElementTypesById;
  renderElementMenu: (index: number) => void;
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
}

const EntryEventRow: React.FC<IEntryEventRowProps> = ({
  chipName,
  data,
  pick,
  element,
  renderElementMenu,
  stats,
  teamsById,
}) => (
  <ElementRow>
    <td>
      <ElementDialogButton elementId={element.id} variant="list" />
    </td>
    <td>
      {pick.is_captain &&
        (chipName === "3xc" ? (
          <StyledCaptain variant="3xc" />
        ) : (
          <StyledCaptain />
        ))}
      {pick.is_vice_captain &&
        (chipName === "3xc" ? (
          <StyledViceCaptain variant="3xc" />
        ) : (
          <StyledViceCaptain />
        ))}
    </td>
    <td>
      <ElementInTable
        renderElementMenu={() => renderElementMenu(pick.element)}
        element={element}
        team={teamsById[element.team]}
      />
    </td>
    <td>{data.total_points * Math.max(pick.multiplier, 1)}</td>
    {stats.map((s) => (
      <td key={s.name}>{data[s.name]}</td>
    ))}
  </ElementRow>
);

interface IOwnProps {
  chipName: string | null;
  dataById: IElementsEventDataById | null;
  picks: IPickLight[];
  renderElementMenu: (index: number) => void;
  title: string;
}

interface IPropsFromState {
  elementsById: IElementsById;
  elementTypesById: IElementTypesById;
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
}

type Props = IOwnProps & IPropsFromState;

const EntryEventTable: React.FC<Props> = ({
  dataById,
  elementsById,
  picks,
  stats,
  title,
  ...rest
}) => {
  if (!dataById) {
    return null;
  }

  const includedStats = getIncludedStats(stats);
  return (
    <Panel>
      <ScrollTable>
        <StatsTable>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>{title}</th>
              <th>Pts</th>
              {includedStats.map((s) => (
                <th key={s.name}>
                  <abbr title={s.label}>
                    {s.label
                      .split(" ")
                      .map((w) => w[0])
                      .join("")
                      .toUpperCase()}
                  </abbr>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {picks.map((p) => (
              <EntryEventRow
                key={p.element}
                pick={p}
                element={elementsById[p.element]}
                stats={includedStats}
                data={dataById[p.element].stats}
                {...rest}
              />
            ))}
          </tbody>
        </StatsTable>
      </ScrollTable>
    </Panel>
  );
};

export { EntryEventTable as EntryEventTableTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    stats: getElementStats(state),
    teamsById: getTeamsById(state),
  };
};

export default connect(mapStateToProps)(EntryEventTable);
