import { RouteComponentProps } from "@reach/router";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../core/store";
import {
  getEntry,
  getEntryChipHistory,
  getEntryEventHistory,
  getEntrySeasonHistory,
} from "../core/store/entries/reducers";
import {
  fetchEntryHistory,
  fetchEntrySummary,
} from "../core/store/entries/thunks";
import {
  IEntry,
  IEntryChipHistory,
  IEntryEventHistory,
  IEntrySeasonHistory,
} from "../core/store/entries/types";
import { getPlayerData } from "../core/store/player/reducers";
import { formatRawAsLocalI18n } from "../core/utils/datetime";
import { integerToMoney } from "../core/utils/money";
import { dateLocales } from "../i18n";
import { ChipName, getChipName } from "../utils/chips";
import Entry from "./Entry";
import { Main, Secondary, Wrapper } from "./Layout";
import Link from "./Link";
import SubHeading from "./SubHeading";
import Table, { ScrollTable } from "./Table";
import Title from "./Title";
import Movement from "./leagues/Movement";

type OwnProps = RouteComponentProps<{ entryId: string }>;

interface IPropsFromState {
  chipHistory: IEntryChipHistory[];
  entry: IEntry | null;
  eventHistory: IEntryEventHistory[];
  mine: boolean;
  seasonHistory: IEntrySeasonHistory[];
}

interface IPropsFromDispatch {
  fetchEntryHistory: (entryId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
}

type Props = OwnProps & WithTranslation & IPropsFromState & IPropsFromDispatch;

class EntryHistory extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchEntryHistory(Number(this.props.entryId));
    this.props.fetchEntrySummary(Number(this.props.entryId));
  }

  public movementFromIndex(index: number): React.ReactNode {
    const thisRank = this.props.eventHistory[index].overall_rank;
    const lastRank =
      index === 0 ? null : this.props.eventHistory[index - 1].overall_rank;
    return <Movement lastRank={lastRank} rank={thisRank} />;
  }

  public render() {
    const { entry, i18n, t } = this.props;
    if (!entry) {
      return null;
    }
    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>{t("entryHistory.title", "Entry History")}</Title>
          </Box>

          <Box mb={4} mt={4}>
            <Box mx={2} mb={2}>
              <SubHeading>
                {t("entryHistory.thisSeason", "This Season")}
              </SubHeading>
            </Box>
            <ScrollTable>
              <Table>
                <thead>
                  <tr>
                    <th>RD</th>
                    <th>GP</th>
                    <th>PB</th>
                    <th>GR</th>
                    <th>TM</th>
                    <th>TC</th>
                    <th>OP</th>
                    <th>OR</th>
                    <th>kr</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.eventHistory.map((eh, i) => (
                    <tr key={eh.event}>
                      <td>
                        <Link to={`/entry/${entry.id}/event/${eh.event}`}>
                          RD{eh.event}
                        </Link>
                      </td>
                      <td>{eh.points}</td>
                      <td>{eh.points_on_bench}</td>
                      <td>{eh.rank ? eh.rank.toLocaleString() : "-"}</td>
                      <td>{eh.event_transfers}</td>
                      <td>{eh.event_transfers_cost}</td>
                      <td>{eh.total_points}</td>
                      <td>
                        {eh.overall_rank
                          ? eh.overall_rank.toLocaleString()
                          : "-"}
                      </td>
                      <td>{integerToMoney(eh.value, 10)}</td>
                      <td>{this.movementFromIndex(i)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ScrollTable>
          </Box>

          <Box mb={4}>
            <Box mx={2} mb={2}>
              <SubHeading>{t("entryHistory.chips", "Chips")}</SubHeading>
            </Box>
            <Table>
              <thead>
                <tr>
                  <th>{t("entryHistory.date", "Date")}</th>
                  <th>{t("entryHistory.name", "Name")}</th>
                  <th>{t("entryHistory.active", "Active")}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.chipHistory.map((ch, i) => (
                  <tr key={ch.event}>
                    <td>
                      {formatRawAsLocalI18n(
                        ch.time,
                        dateLocales[i18n.language]
                      )}
                    </td>
                    <td>{getChipName(ch.name as ChipName, t)}</td>
                    <td>
                      <Link to={`/entry/${entry.id}/event/${ch.event}`}>
                        RD{ch.event}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>

          <Box mb={4}>
            <Box mx={2} mb={2}>
              <SubHeading>
                {t("entryHistory.previousSeasons", "Previous Seasons")}
              </SubHeading>
            </Box>
            <Table>
              <thead>
                <tr>
                  <th>{t("entryHistory.season", "Season")}</th>
                  <th>{t("entryHistory.points", "Points")}</th>
                  <th>{t("entryHistory.Rank", "Rank")}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.seasonHistory.map((sh) => (
                  <tr key={sh.season_name}>
                    <td>{sh.season_name}</td>
                    <td>{sh.total_points}</td>
                    <td>{sh.rank}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Main>
        <Secondary>
          <Entry entryId={entry.id} />
        </Secondary>
      </Wrapper>
    );
  }
}

export { EntryHistory as EntryHistoryTest };

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const entryId = Number(ownProps.entryId);
  const player = getPlayerData(state);
  return {
    chipHistory: getEntryChipHistory(state, entryId),
    entry: getEntry(state, entryId),
    eventHistory: getEntryEventHistory(state, entryId),
    mine: Boolean(player && player.entry && player.entry === entryId),
    seasonHistory: getEntrySeasonHistory(state, entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntryHistory: (entryId: number) => dispatch(fetchEntryHistory(entryId)),
  fetchEntrySummary: (entryId: number) => dispatch(fetchEntrySummary(entryId)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EntryHistory)
);
