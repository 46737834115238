import { size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as ArrowLeftIcon } from "../../img/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../img/icons/arrow-right.svg";
import { ReactComponent as ArrowLeftDoubleIcon } from "../../img/icons/arrow-double-left.svg";
import { ReactComponent as ArrowRightDoubleIcon } from "../../img/icons/arrow-double-right.svg";
import { ReactComponent as ArrowRightLongIcon } from "../../img/icons/arrow-right-long.svg";
import { ReactComponent as ArrowLeftLongIcon } from "../../img/icons/arrow-left-long.svg";

interface IArrow {
  color?: "black";
}

export const ArrowLeft = styled(ArrowLeftIcon)<IArrow>`
  fill: ${(props) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
`;

export const ArrowRight = styled(ArrowRightIcon)<IArrow>`
  fill: ${(props) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
`;

export const ArrowLeftDouble = styled(ArrowLeftDoubleIcon)<IArrow>`
  fill: ${(props) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
`;

export const ArrowRightDouble = styled(ArrowRightDoubleIcon)<IArrow>`
  fill: ${(props) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
`;

export const ArrowRightLong = styled(ArrowRightLongIcon)<IArrow>`
  fill: ${(props) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
`;

export const ArrowLeftLong = styled(ArrowLeftLongIcon)<IArrow>`
  fill: ${(props) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
`;

export const ControlArrowRight = styled(ArrowRight)`
  ${size("16px")};
  margin-left: 0.5rem;
`;

export const ControlArrowLeft = styled(ArrowLeft)`
  ${size("16px")};
  margin-right: 0.5rem;
`;
