import React, { useEffect, useRef } from "react";
import AdWrap from "./AdWrap";

const MedRecAd: React.FC = () => {
  const adSlot = useRef<any>(null);

  // Loading of ad and cleanup goes in first useEffect effectively handling mount and unmount
  useEffect(() => {
    const googletag = (window as any).googletag;
    googletag?.cmd?.push(() => {
      adSlot.current = googletag
        .defineSlot(
          "/64964091/FantasyRightColumn",
          [[300, 250]],
          "div-gpt-ad-1612861196737-0"
        )
        .addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
      googletag.pubads().collapseEmptyDivs();
    });
    // cleanup
    return () => {
      googletag &&
        googletag.pubadsReady &&
        googletag.destroySlots([adSlot.current]);
    };
  }, []);

  // Showing of ad has to go in separate useEffect because can get called twice on pages like Points (component update)
  useEffect(() => {
    const googletag = (window as any).googletag;
    googletag?.cmd?.push(() => {
      googletag.display("div-gpt-ad-1612861196737-0");
    });
  }, []);

  return <AdWrap id="div-gpt-ad-1612861196737-0" />;
};

export default MedRecAd;
