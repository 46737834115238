import * as React from "react";
import { ITeam } from "../core/store/teams/types";
import styled from "styled-components/macro";

const StyledBadge = styled.img`
  display: inline-block;
  vertical-align: middle;
`;

const badgePath = `${process.env.PUBLIC_URL}/dist/img/badges`;
const prefix = "badge_";

interface IProps {
  isPresentation?: boolean;
  sizes: string;
  team: ITeam | null;
}

const Badge: React.FC<IProps> = ({ isPresentation, sizes, team }) => {
  const code = team ? team.code : 0;
  const path = `${badgePath}/${prefix}${code}`;

  const imgProps = {
    alt: team?.name || "",
    role: "",
    sizes,
    src: `${path}-50.png`,
    srcSet: `${path}-50.png 50w, ${path}-100.png 100w`,
  };

  if (isPresentation) {
    imgProps.alt = "";
    imgProps.role = "presentation";
  }

  return <StyledBadge {...imgProps} />;
};

export default Badge;
