import React from "react";
import styled from "styled-components/macro";

const StyledPanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const StyledPanelBody = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    border-radius: 6px;
  }
`;

interface IPanelHeaderProps {
  children: React.ReactNode;
  link?: React.ReactNode;
}

const StatusPanelHeader: React.FC<IPanelHeaderProps> = ({ children, link }) => (
  <StyledPanelHeader>
    {children}
    {link}
  </StyledPanelHeader>
);

interface IPanelBodyProps {
  children: React.ReactNode;
}

const StatusPanelBody: React.FC<IPanelBodyProps> = ({ children }) => (
  <StyledPanelBody>{children}</StyledPanelBody>
);

interface IPanelProps {
  children?: React.ReactNode;
}

class StatusPanel extends React.Component<IPanelProps> {
  public static Header = StatusPanelHeader;
  public static Body = StatusPanelBody;

  render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
}

export default StatusPanel;
