import React from "react";
import styled from "styled-components/macro";
import LogoBar from "./LogoBar";

const Heading = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 100;
  font-family: ${({ theme }) => theme.fonts.impact};
  color: ${({ theme }) => theme.colors.black};
`;

const Deadline = styled.time`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.impact};
  color: ${({ theme }) => theme.colors.black};
`;

interface IProps {
  deadlineISO: string;
  deadlineLocal: string;
  headingText: string;
}

const DeadlineBar: React.FC<IProps> = ({
  deadlineISO,
  deadlineLocal,
  headingText,
}) => (
  <LogoBar justifyContent="center">
    <Heading>{headingText}:</Heading>
    <span>&nbsp;</span>
    <Deadline dateTime={deadlineISO}>{deadlineLocal}</Deadline>
  </LogoBar>
);

export default DeadlineBar;
