import { RouteComponentProps } from "@reach/router";
import { hiDPI, size } from "polished";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import i18n from "../i18n";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
// Hero images
import heroImg1x from "../img/home/hero.jpg";
import heroImg2x from "../img/home/hero@2x.jpg";
import step1 from "../img/home/step-1.jpg";
import step12x from "../img/home/step-1@2x.jpg";
import step13x from "../img/home/step-1@3x.jpg";
// Highlight images
import step2 from "../img/home/step-2.jpg";
import step22x from "../img/home/step-2@2x.jpg";
import step23x from "../img/home/step-2@3x.jpg";
import { ReactComponent as AltiboxLogo } from "../img/logos/altibox-logo.svg";
import { ReactComponent as Tv2Logo } from "../img/logos/tv2-logo.svg";
import { ReactComponent as ObosLogo } from "../img/logos/obos-logo.svg";
import { Main, Wrapper } from "./Layout";
import Login from "./Login";
import Status from "./status/Status";
import TriangleCard from "./TriangleCard";
import ScoutProvider from "./scout/ScoutProvider";

const HomeHero = styled.div`
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-image: url(${heroImg1x});

  ${hiDPI(2)} {
    background-image: url(${heroImg2x});
    background-size: cover;
  }

  margin: 0 ${({ theme }) => theme.space[3]};
  margin-bottom: 100px;
  padding-top: 270px;
  text-align: center;
  height: 0;
  position: relative;
  border-radius: 12px;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    border-radius: 12px;
  }
`;

const Tagline = styled.h3`
  display: inline-block;
  margin: 0 ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[5]};
  background: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 20px;
  transform: translateY(-50%);
  line-height: 1;
  border-radius: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 34px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 52px;
  }
`;

const Highlights = styled.div`
  margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const HighlightsItem = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(43%);
    margin-bottom: 0;
  }
`;

const SponsorBarOuter = styled.div`
  background-color: #f2f9fa;
`;

const SponsorBarInner = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    max-width: 1220px;
    margin: auto;
  }
`;

const SponsorList = styled.ul`
  margin: 0;
  padding: ${({ theme }) => theme.space[2]};
  list-style-type: none;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;

const SponsorItem = styled.li`
  margin-bottom: ${({ theme }) => theme.space[3]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 40%;
    margin-bottom: 0;
    padding: 0 3rem;
    text-align: right;

    :not(:first-child) {
      border-left: 2px solid #a5a5a5;
      text-align: left;
    }
  }
`;

const SponsorBox = styled.div`
  text-align: center;
  display: inline-block;
`;

const SponsorHeading = styled.h3`
  margin: 0 0 ${({ theme }) => theme.space[2]};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.baseSemiBold};
  color: ${({ theme }) => theme.colors.black};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-bottom: 0.5rem;
  }
`;

const StyledAltiboxLogo = styled(AltiboxLogo)`
  ${size(37, 100)}
  padding: 0 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(55, 133)}
  }
`;

const StyledObosLogo = styled(ObosLogo)`
  ${size(37, 100)}
  padding: 0 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(55, 133)}
  }
`;

const YoutubeEmbed = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) =>
    `${theme.space[8]}  ${theme.space[2]} ${theme.space[2]}`};
`;

const PresentedList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  list-style-type: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: 2rem;
  }
`;

const PresentedItem = styled.li`
  padding: 0;
  line-height: 1;

  :not(:last-child) {
    padding-right: ${({ theme }) => theme.space[2]};
  }
`;

const StyledTv2Logo = styled(Tv2Logo)`
  ${size(32, 41)}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(40, 53)}
    margin-top: -5px;
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
}

interface IProvider {
  link: string;
  url: string;
  title: string;
}

type Props = RouteComponentProps & IPropsFromState & WithTranslation;

class Home extends React.Component<Props> {
  public componentDidMount() {
    this.TrackFacebook();
  }

  public TrackFacebook() {
    const fbq = (window as any).fbq;
    if (fbq) {
      fbq("trackCustom", "FPLLanding");
    }
  }

  public render() {
    const { t, now, player } = this.props;

    const providers: IProvider[] = [
      {
        url: "https://www.tv2.no/rest/cms-feeds-dw-rest/v2/cms/article/sport/fotball/fantasy",
        link: "https://www.tv2.no/sport/fotball/eliteserien",
        title: i18n.t("feed.providers.title1", "Latest Fantasy-news from TV 2"),
      },
      {
        url: "https://www.eliteserien.no/rss-nyheter",
        link: "https://www.eliteserien.no/nyheter",
        title: i18n.t("feed.providers.title2", "Latest news from Eliteserien"),
      },
    ];

    // Show the status page if have a team and the game has started
    if (now && player && player.entry) {
      return <Status />;
    }

    return (
      <>
        <Wrapper>
          <Main isWide={true}>
            <Login />
            <HomeHero>
              <Tagline>
                {t("home.title", "Play the official Eliteserien fantasy game")}
              </Tagline>
            </HomeHero>
            <Highlights>
              <HighlightsItem>
                <TriangleCard
                  title={t("home.highlightsLeftTitle", "Choose Your Team")}
                  desc={t(
                    "home.highlightsLeftDesc",
                    "Use the 100mNOK budget to set up your team of 15 players from the Eliteserien"
                  )}
                  lgSrc={step13x}
                  mdSrc={step12x}
                  smSrc={step1}
                />
              </HighlightsItem>
              <HighlightsItem>
                <TriangleCard
                  title={t(
                    "home.highlightsCenterTitle",
                    "Participate in Leagues"
                  )}
                  desc={t(
                    "home.highlightsCenterDesc",
                    "Compete against friends, family and colleagues in your own private leagues"
                  )}
                  lgSrc={step23x}
                  mdSrc={step22x}
                  smSrc={step2}
                />
              </HighlightsItem>
            </Highlights>

            <YoutubeEmbed>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/eXcmtgbInZg?si=fSgxG_KK63cUXCOo"
                frameBorder="0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </YoutubeEmbed>

            {providers.map((provider: IProvider) => (
              <ScoutProvider
                key={provider.url}
                link={provider.link}
                url={provider.url}
                title={provider.title}
                numberOfArticles={4}
              />
            ))}
          </Main>
        </Wrapper>
        <SponsorBarOuter>
          <SponsorBarInner>
            <SponsorList>
              <SponsorItem>
                <SponsorBox>
                  <SponsorHeading>
                    {t("home.mainPartner", "Main partner")}
                  </SponsorHeading>
                  <a
                    href="https://www.altibox.no/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledAltiboxLogo />
                  </a>
                  <a
                    href="https://nye.obos.no/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledObosLogo />
                  </a>
                </SponsorBox>
              </SponsorItem>
              <SponsorItem>
                <SponsorBox>
                  <SponsorHeading>
                    {t("home.poweredBy", "Powered by")}
                  </SponsorHeading>
                  <PresentedList>
                    <PresentedItem>
                      <a
                        href="https://www.tv2.no/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StyledTv2Logo />
                      </a>
                    </PresentedItem>
                  </PresentedList>
                </SponsorBox>
              </SponsorItem>
            </SponsorList>
          </SponsorBarInner>
        </SponsorBarOuter>
      </>
    );
  }
}

export { Home as HomeTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
});

export default withTranslation()(connect(mapStateToProps)(Home));
