import { em } from "polished";
import styled from "styled-components/macro";

export const ScrollTable = styled.div`
  @media (max-width: ${em("480px")}) {
    overflow-x: auto;

    table {
      width: 48rem;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: ${({ theme }) => theme.fontSizes[2]};

  th,
  td {
    padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  }

  th {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.primary};
    text-align: left;
  }

  tr {
    background-color: transparent;
    border-top: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

export default Table;
