import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getActiveOrProposedTeamChipName } from "../../core/store/chips/reducers";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElement, IElementsById } from "../../core/store/elements/types";
import { getFixturesForNextEventByTeam } from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import { IPickProposed } from "../../core/store/my-team/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementCell, ElementRow, ElementTable } from "../ElementTable";
import { FixturesForElement } from "../FixtureForElement";
import { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import { StyledCaptain, StyledViceCaptain } from "./TableCaptains";
import { useTranslation } from "react-i18next";
import { withTranslation, WithTranslation } from "react-i18next";
import Panel from "../Panel";

const MyTeamStatus = styled.th`
  width: 6%;
`;

const MyTeamCpt = styled.th`
  width: 4%;
`;

const MyTeamElement = styled.th`
  width: 32%;
`;

const MyTeamPos = styled.th`
  width: 12%;
`;

const MyTeamForm = styled.th`
  width: 10%;
`;

const MyTeamRD = styled.th`
  width: 10%;
`;

const MyTeamPts = styled.th`
  width: 12%;
`;

const MyTeamFix = styled.th`
  width: 14%;
`;

interface IMyTeamRowProps {
  chipInPlayName: string | null;
  pick: IPickProposed;
  element: IElement;
  elementTypesById: IElementTypesById;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  renderElementMenu: (index: number) => void;
  teamsById: ITeamsById;
}

const MyTeamRow: React.FC<IMyTeamRowProps> = ({
  chipInPlayName,
  pick,
  element,
  elementTypesById,
  fixturesForNextEventByTeam,
  renderElementMenu,
  teamsById,
}) => (
  <ElementRow variant={pick.subStatus}>
    <td>
      <ElementDialogButton elementId={element.id} variant="list" />
    </td>
    <td>
      {pick.is_captain &&
        (chipInPlayName === "3xc" ? (
          <StyledCaptain variant="3xc" />
        ) : (
          <StyledCaptain />
        ))}
      {pick.is_vice_captain &&
        (chipInPlayName === "3xc" ? (
          <StyledViceCaptain variant="3xc" />
        ) : (
          <StyledViceCaptain />
        ))}
    </td>
    <ElementCell>
      <ElementInTable
        renderElementMenu={() => renderElementMenu(pick.element)}
        element={element}
        team={teamsById[element.team]}
      />
    </ElementCell>
    <td>{elementTypesById[element.element_type].singular_name_short}</td>
    <td>{element.form}</td>
    <td>{element.event_points}</td>
    <td>{element.total_points}</td>
    <td>
      <FixturesForElement
        delimiter={<br />}
        fixtures={fixturesForNextEventByTeam[element.team]}
        element={element}
        teamsById={teamsById}
      />
    </td>
  </ElementRow>
);

interface IOwnProps {
  picks: IPickProposed[];
  renderElementMenu: (index: number) => void;
  title: string;
}

interface IPropsFromState {
  chipInPlayName: string | null;
  elementsById: IElementsById;
  elementTypesById: IElementTypesById;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  teamsById: ITeamsById;
}

type Props = IOwnProps & IPropsFromState & WithTranslation;

const MyTeamTable: React.FC<Props> = ({
  elementsById,
  picks,
  title,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Panel>
      <ScrollTable>
        <ElementTable>
          <thead>
            <tr>
              <MyTeamStatus>&nbsp;</MyTeamStatus>
              <MyTeamCpt>&nbsp;</MyTeamCpt>
              <MyTeamElement>{title}</MyTeamElement>
              <MyTeamPos>
                <Tooltip content={t("myTeamTable.pos", "Position")}>
                  <TooltipLabel>
                    {t("myTeamTable.label.pos", "Pos")}
                  </TooltipLabel>
                </Tooltip>
              </MyTeamPos>
              <MyTeamForm>{t("myTeamTable.label.from", "Form")}</MyTeamForm>
              <MyTeamRD>
                <Tooltip content={t("myTeamTable.rd", "Round points")}>
                  <TooltipLabel>{t("myTeamTable.label.rd", "RD")}</TooltipLabel>
                </Tooltip>
              </MyTeamRD>
              <MyTeamPts>
                <Tooltip content={t("myTeamTable.pts", "Total points")}>
                  <TooltipLabel>
                    {t("myTeamTable.label.pts", "Pts")}
                  </TooltipLabel>
                </Tooltip>
              </MyTeamPts>
              <MyTeamFix>
                <Tooltip content={t("myTeamTable.fix", "Fixture next Round")}>
                  <TooltipLabel>
                    {t("myTeamTable.label.fix", "Fix")}
                  </TooltipLabel>
                </Tooltip>
              </MyTeamFix>
            </tr>
          </thead>
          <tbody>
            {picks.map((p) => (
              <MyTeamRow
                key={p.element}
                pick={p}
                element={elementsById[p.element]}
                {...rest}
              />
            ))}
          </tbody>
        </ElementTable>
      </ScrollTable>
    </Panel>
  );
};

export { MyTeamTable as MyTeamTableTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    chipInPlayName: getActiveOrProposedTeamChipName(state),
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    fixturesForNextEventByTeam: getFixturesForNextEventByTeam(state),
    teamsById: getTeamsById(state),
  };
};

export default withTranslation()(connect(mapStateToProps)(MyTeamTable));
