import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import {
  getCupMatchesByLeagueId,
  getEntry,
} from "../../core/store/entries/reducers";
import {
  CupMatchesByLeagueId,
  ILeagueEntry,
} from "../../core/store/entries/types";
import { getPlayerData } from "../../core/store/player/reducers";
import Panel from "../Panel";
import Table from "../Table";
import { VisuallyHidden } from "../Utils";
import CupTypeRow from "./CupTypeRow";

const CupCol = styled.th`
  width: 56%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 72%;
  }
`;

const RoundCol = styled.th`
  width: 22%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 12%;
  }
`;

const StatusCol = styled.th`
  width: 22%;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 16%;
  }
`;

const MyCupsTable = styled(Table)`
  table-layout: fixed;

  th,
  td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  /* Have to do this because of Table td specificity */
  ${StatusCol} {
    text-align: center;
  }
`;

interface IProps {
  leagues: ILeagueEntry[] | null;
  title: string;
}

const CupTypeTable: React.FC<IProps> = ({ leagues, title }) => {
  const { t } = useTranslation();
  const player = useSelector(getPlayerData);
  const entry = useSelector((state: RootState) =>
    player?.entry ? getEntry(state, player.entry) : null
  );

  const cupMatches: CupMatchesByLeagueId | null = useSelector(
    (state: RootState) =>
      entry ? getCupMatchesByLeagueId(state, entry.id) : null
  );

  if (!leagues || !entry) {
    return null;
  }

  return (
    <Panel>
      <Panel.Header title={title} />
      <Panel.Body>
        <MyCupsTable>
          <thead>
            <tr>
              <CupCol scope="col">{t("cupTypeTable.cup", "Cup")}</CupCol>
              <RoundCol scope="col">
                <VisuallyHidden>
                  {t("cupTypeTable.round", "Round")}
                </VisuallyHidden>
              </RoundCol>
              <StatusCol scope="col">
                <VisuallyHidden>
                  {t("cupTypeTable.status", "Status")}
                </VisuallyHidden>
              </StatusCol>
            </tr>
          </thead>
          <tbody>
            {leagues.map((l) => (
              <CupTypeRow
                key={l.id}
                cupMatch={
                  l.cup_league && cupMatches ? cupMatches[l.cup_league] : null
                }
                entryId={entry.id}
                league={l}
              />
            ))}
          </tbody>
        </MyCupsTable>
      </Panel.Body>
    </Panel>
  );
};

export default CupTypeTable;
