import * as React from "react";
import styled from "styled-components/macro";

interface IStyledProps {
  ref?: any;
}

interface IProps {
  children: React.ReactNode;
  onClick?: () => void;
}

type Props = IStyledProps & IProps;

const StyledBoldLinkButton = styled.button<IStyledProps>`
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: inherit;
  text-decoration: none;
  text-align: left;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const BoldLinkButton: React.ForwardRefExoticComponent<Props> = React.forwardRef(
  ({ children, ...rest }, ref) => (
    <StyledBoldLinkButton {...rest} ref={ref}>
      {children}
    </StyledBoldLinkButton>
  )
);

export default BoldLinkButton;
