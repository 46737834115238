import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import { updateElementTypeControl } from "../../core/store/elements/thunks";
import { getFixturesForNextEventByTeam } from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import { getProposedElements } from "../../core/store/squad/reducers";
import { IProposedElements } from "../../core/store/squad/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToMoney } from "../../core/utils/money";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementCell, ElementRow, ElementTable } from "../ElementTable";
import { FixturesForElement } from "../FixtureForElement";
import Tooltip, { TooltipLabel } from "../Tooltip";
import { useTranslation } from "react-i18next";

const ScrollWrapper = styled.div`
  overflow-x: auto;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    overflow-x: visible;
  }
`;

const FlexElementCell = styled(ElementCell)`
  display: flex;
  align-items: center;
`;

const FilterElTypeButton = styled.button`
  padding: 10px 0 9px;
  border: 0;
  background-color: transparent;
  font-size: 1.3rem;
  white-space: normal;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SquadListElement = styled.th`
  width: 40%;
`;

const SquadListCost = styled.th`
  width: 15%;
`;

const SquadListSelected = styled.th`
  width: 15%;
`;

const SquadListPoints = styled.th`
  width: 15%;
`;

const SquadListFixture = styled.th`
  width: 15%;
`;

interface ISquadListRowProps {
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  proposedElements: IProposedElements;
  pos: number;
  renderElementMenu: (position: number) => void;
  showElementType: (elementTypeId: number) => void;
  teamsById: ITeamsById;
}

const SquadListRow: React.FC<ISquadListRowProps> = ({
  elementTypesByPosition,
  proposedElements,
  pos,
  renderElementMenu,
  showElementType,
  teamsById,
  fixturesForNextEventByTeam,
}) => {
  const { t } = useTranslation();

  if (!elementTypesByPosition) {
    return null;
  }

  const element = proposedElements[pos];
  return (
    <ElementRow>
      {element ? (
        <>
          <FlexElementCell>
            <div>
              <ElementDialogButton elementId={element.id} variant="list" />
            </div>
            <ElementInTable
              renderElementMenu={() => renderElementMenu(pos)}
              element={element}
              team={teamsById[element.team]}
            />
          </FlexElementCell>
          <td>{integerToMoney(element.now_cost, 10)}</td>
          <td>{element.selected_by_percent}</td>
          <td>{element.total_points}</td>
          <td>
            <FixturesForElement
              delimiter={<br />}
              fixtures={fixturesForNextEventByTeam[element.team]}
              element={element}
              teamsById={teamsById}
            />
          </td>
        </>
      ) : (
        <>
          <td colSpan={5}>
            <FilterElTypeButton
              onClick={() =>
                showElementType(elementTypesByPosition[pos].thisType.id)
              }
            >
              {t("squad.selectElement", "Select {{position}}", {
                position: elementTypesByPosition[pos].thisType.singular_name,
              })}
            </FilterElTypeButton>
          </td>
        </>
      )}
    </ElementRow>
  );
};

interface IPropsFromState {
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  proposedElements: IProposedElements;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  showElementType: (elementTypeId: number) => void;
}

interface ISquadListTableProps {
  elementType: number;
  positions: number[];
  renderElementMenu: (pos: number) => void;
}

type Props = IPropsFromState & IPropsFromDispatch & ISquadListTableProps;

const SquadListTable: React.SFC<Props> = ({
  elementType,
  elementTypesByPosition,
  positions,
  ...rest
}) => {
  const { t } = useTranslation();

  if (!elementTypesByPosition) {
    return null;
  }
  return (
    <ScrollWrapper>
      <ElementTable elementType={elementType}>
        <thead>
          <tr>
            <SquadListElement>
              {elementTypesByPosition[positions[0]].thisType.plural_name}
            </SquadListElement>
            <SquadListCost>
              <Tooltip content={t("price", "Price")}>
                <TooltipLabel>mNOK</TooltipLabel>
              </Tooltip>
            </SquadListCost>
            <SquadListSelected>
              <Tooltip
                content={t("squadListTabel.selectedPct", "Teams selected by %")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.selectedPct", "SB")}
                </TooltipLabel>
              </Tooltip>
            </SquadListSelected>
            <SquadListPoints>
              <Tooltip
                content={t("squadListTabel.totalPoints", "Total points")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.totalPoints", "TP")}
                </TooltipLabel>
              </Tooltip>
            </SquadListPoints>
            <SquadListFixture>
              <Tooltip
                content={t("squadListTabel.fixtureNext", "Fixture next")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.fixtureNext", "Fix")}
                </TooltipLabel>
              </Tooltip>
            </SquadListFixture>
          </tr>
        </thead>
        <tbody>
          {positions.map((p) => (
            <SquadListRow
              key={p}
              pos={p}
              elementTypesByPosition={elementTypesByPosition}
              {...rest}
            />
          ))}
        </tbody>
      </ElementTable>
    </ScrollWrapper>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementTypesByPosition: getElementTypesBySquadPosition(state),
  fixturesForNextEventByTeam: getFixturesForNextEventByTeam(state),
  proposedElements: getProposedElements(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementType: (elementTypeId) =>
    dispatch(updateElementTypeControl(elementTypeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SquadListTable);
