import React, { useEffect, useRef } from "react";
import AdWrap from "./AdWrap";

const LeaderboardAd: React.FC = () => {
  const adSlot = useRef<any>(null);

  // Loading of ad and cleanup goes in first useEffect effectively handling mount and unmount
  useEffect(() => {
    const googletag = (window as any).googletag;
    googletag?.cmd?.push(() => {
      /* Size-mapping for Fantasy Leaderboard ad*/
      var mapping_fantasy_leaderboard = googletag
        .sizeMapping()
        .addSize([1300, 0], [[728, 90]])
        .addSize([1024, 0], [[728, 90]])
        .addSize([640, 0], [[300, 90]])
        .addSize([0, 0], [[300, 90]])
        .build();

      adSlot.current = googletag
        .defineSlot(
          "/64964091/FantasyLeaderBoard",
          [
            [300, 90],
            [728, 90],
          ],
          "div-gpt-ad-1612959919603-0"
        )
        .defineSizeMapping(mapping_fantasy_leaderboard)
        .addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
      googletag.pubads().collapseEmptyDivs();
    });
    // cleanup
    return () => {
      googletag &&
        googletag.pubadsReady &&
        googletag.destroySlots([adSlot.current]);
    };
  }, []);

  // Showing of ad has to go in separate useEffect because can get called twice on pages like Points (component update)
  useEffect(() => {
    const googletag = (window as any).googletag;
    googletag?.cmd?.push(() => {
      googletag.display("div-gpt-ad-1612959919603-0");
    });
  }, []);

  return <AdWrap id="div-gpt-ad-1612959919603-0" />;
};

export default LeaderboardAd;
