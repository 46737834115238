import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import LogoBar from "../LogoBar";
import SubHeading from "../SubHeading";
import { ControlArrowRight } from "../icons/Arrows";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch, RootState } from "../../core/store";
import { fetchURL } from "../../core/store/urls/thunks";
import { getURL } from "../../core/store/urls/reducers";
import Parser from "rss-parser";
import filterOnSSL from "./filterOnSSL";

const parser = new Parser();

const StyledProvider = styled.section`
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[2]};
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: ${({ theme }) => theme.space[0]};
  }
`;

const ProviderHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const StyledLogoBar = styled(LogoBar)`
  border: none;
  justify-content: space-between;
  > :first-child {
    display: none;
  }
`;

const ProviderContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
`;

const StyledLink = styled.a`
  color: #000;
  text-decoration: none;
`;

const Article = styled.article`
  padding: ${({ theme }) => theme.space[4]};
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueDarker4};
    border-radius: ${({ theme }) => theme.radii[0]};

    ${StyledLink} {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: ${({ theme }) => theme.space[2]};
    width: 33%;
  }
`;

const ArticleFeatureImg = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: ${({ theme }) => theme.radii[0]};
  aspect-ratio: 14/9;
`;

const StyledMoreLink = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.red};
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

interface IProps {
  link: string;
  url: string;
  title: string;
  numberOfArticles: number;
}

const ScoutProvider: React.FC<IProps> = ({
  link,
  url,
  title,
  numberOfArticles,
}) => {
  const { t } = useTranslation();
  const [providerData, setProviderData] = useState<Parser.Item[] | null>(null);

  // Fetch RSS
  const reduxDispatch = useDispatch<ThunkDispatch>();
  useEffect(() => {
    reduxDispatch(fetchURL(url));
  }, [reduxDispatch, url]);

  // Get raw RSS content, will be null until we have some
  const content = useSelector((state: RootState) => getURL(state, url));

  // Parse RSS data
  useEffect(() => {
    const parseContent = async () => {
      try {
        const feed = await parser.parseString(content.text);
        if (feed && feed.items) {
          const filteredSslFeedItems = filterOnSSL(feed.items).splice(
            0,
            numberOfArticles
          );

          setProviderData(filteredSslFeedItems);
        }
      } catch (e) {}
    };
    if (content) {
      parseContent();
    }
  }, [content, numberOfArticles]);

  if (!providerData) {
    return null;
  }

  return (
    <StyledProvider style={{ marginBottom: 20 }}>
      <ProviderHeader>
        <StyledLogoBar justifyContent="left">
          <SubHeading variant="dark">{title}</SubHeading>
          <StyledMoreLink href={link} target="_blank" rel="noopener noreferrer">
            {title === "Siste Fantasy-nyheter"
              ? t("scout.moreNews", "More news")
              : t("scout.more", "More")}
            <ControlArrowRight />
          </StyledMoreLink>
        </StyledLogoBar>
      </ProviderHeader>
      <ProviderContent>
        {providerData.map((item: Parser.Item) => {
          return (
            <Article key={item.title}>
              <StyledLink
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.enclosure && (
                  <ArticleFeatureImg
                    src={item.enclosure.url}
                    alt="Article fea"
                  />
                )}
                <h4>{item.title}</h4>
              </StyledLink>
            </Article>
          );
        })}
      </ProviderContent>
    </StyledProvider>
  );
};

export default ScoutProvider;
