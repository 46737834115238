import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getH2HLeague,
  getH2HNewEntries,
} from "../../core/store/leagues/reducers";
import { fetchH2HLeagueStandings } from "../../core/store/leagues/thunks";
import Button from "../Button";
import { ControlArrowLeft, ControlArrowRight } from "../icons/Arrows";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import { Pager, PagerItem, PagerItemNext } from "../Pager";
import SubHeading from "../SubHeading";
import Table from "../Table";
import Panel from "../Panel";
import StandingsHeading from "./StandingsHeading";

type OwnProps = RouteComponentProps<{
  leagueId: string;
}>;

const NewEntriesH2H: React.FC<OwnProps> = ({ leagueId }) => {
  const { t } = useTranslation();
  const leagueNumber = Number(leagueId);
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const [page, setPage] = useState(1);
  const league = useSelector((state: RootState) =>
    getH2HLeague(state, leagueNumber)
  );
  const newEntries = useSelector((state: RootState) =>
    getH2HNewEntries(state, leagueNumber, page)
  );
  useEffect(() => {
    reduxDispatch(fetchH2HLeagueStandings(leagueNumber, page, 1));
  }, [reduxDispatch, page, leagueNumber]);

  if (!league || !newEntries) {
    return null;
  }

  return (
    <Wrapper>
      <Main>
        <StandingsHeading
          type="h2h"
          league={league}
          leagueNumber={leagueNumber}
        />
        <Box mx={2}>
          <SubHeading>{t("standingH2h.new", "New entries")}</SubHeading>
          <Panel>
            <Table>
              <thead>
                <tr>
                  <th scope="col">{t("standingH2h.team1", "Team")}</th>
                  <th scope="col">{t("standingH2h.manager1", "Manager")}</th>
                </tr>
              </thead>
              <tbody>
                {newEntries.results.map((ne) => (
                  <tr key={ne.entry}>
                    <td>{ne.entry_name}</td>
                    <td>
                      <Link to={`/entry/${ne.entry}/history`}>
                        {ne.player_first_name} {ne.player_last_name}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Panel>
          <Pager>
            <PagerItem>
              {page > 1 && (
                <Button onClick={() => setPage(page - 1)} variant="secondary">
                  <ControlArrowLeft />
                  <span>Previous</span>
                </Button>
              )}
            </PagerItem>
            <PagerItemNext>
              {newEntries.has_next && (
                <Button onClick={() => setPage(page + 1)} variant="secondary">
                  <span>Next</span>
                  <ControlArrowRight />
                </Button>
              )}
            </PagerItemNext>
          </Pager>
        </Box>
      </Main>
      <Secondary>
        <></>
      </Secondary>
    </Wrapper>
  );
};

export default NewEntriesH2H;
