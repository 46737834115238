import React from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "rebass/styled-components";
import { IElement } from "../../core/store/elements/types";
import {
  ArrowLeft,
  ArrowLeftDouble,
  ArrowRight,
  ArrowRightDouble,
} from "../icons/Arrows";
import PaginatorButton from "../paginator/PaginatorButton";
import styled from "styled-components/macro";

const PageCount = styled.div`
  color: ${({ theme }) => theme.colors.blackLighter};
`;

export const paginate = (
  elements: IElement[],
  page: number,
  pageSize: number
) => {
  const start = (page - 1) * pageSize;
  return {
    data: elements.slice(start, start + pageSize),
    totalPages: Math.ceil(elements.length / pageSize),
  };
};

interface IProps {
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
}

const Paginator: React.FunctionComponent<IProps> = ({
  page,
  setPage,
  totalPages,
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="space-between" alignItems="center" p={2}>
      <PaginatorButton
        actionMe={() => setPage(1)}
        label={t("paginator.first", "First")}
        disabled={page === 1}
        isSmall={true}
      >
        <ArrowLeftDouble fill="white" />
      </PaginatorButton>
      <PaginatorButton
        actionMe={() => setPage(page - 1)}
        label={t("paginator.previous", "Previous")}
        disabled={page === 1}
      >
        <ArrowLeft fill="white" />
      </PaginatorButton>
      <PageCount>
        <strong>{page}</strong> of {totalPages}
      </PageCount>
      <PaginatorButton
        actionMe={() => setPage(page + 1)}
        label={t("paginator.next", "Next")}
        disabled={page === totalPages}
      >
        <ArrowRight fill="white" />
      </PaginatorButton>
      <PaginatorButton
        actionMe={() => setPage(totalPages)}
        label={t("paginator.last", "Last")}
        disabled={page === totalPages}
        isSmall={true}
      >
        <ArrowRightDouble fill="white" />
      </PaginatorButton>
    </Flex>
  );
};

export default Paginator;
