import { Flex } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { SubStatus } from "../core/store/my-team/types";
import { rgba } from "polished";
import PitchSvg from "../img/pitch.svg";

const Pitch = styled.div`
  min-height: 330px;
  background-image: url(${PitchSvg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 516px 330px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 524px;
    background-size: 820px 524px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 690px;
    background-size: 1080px 690px;
  }
`;

export const ElementRow = styled(Flex)`
  max-width: 410px;
  margin: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: 620px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    max-width: none;
  }
`;

export const PitchRow = styled(ElementRow)`
  padding-bottom: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-bottom: 2.8rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-bottom: 4.5rem;
  }
`;

export const PitchUnit = styled.div`
  flex: 1;
  min-height: 7.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 9.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 11.6rem;
  }
`;

export const PitchElementWrap = styled.div<IWrapStyleProps>`
  margin: auto;
  padding: 0.4rem 0;
  border-radius: 0.8rem;
  align-items: stretch;
  width: 7.8rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 10.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 12.8rem;
  }

  ${(props) =>
    (props.variant === "instigator" || props.variant === "target") &&
    css`
      transition: all 0.15s;
    `}

  ${(props) =>
    props.variant === "instigator" &&
    css`
      background-color: ${({ theme }) => theme.colors.pinkLighter};
    `}

  ${(props) =>
    props.variant === "target" &&
    css`
      background-color: ${rgba("#100118", 0.2)};
    `}

  ${(props) =>
    props.variant === "invalid" &&
    css`
      opacity: 0.6;
    `}
`;

export const StyledPitchElement = styled.div<IPitchElementStyleProps>`
  position: relative;
  margin: auto;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  text-align: center;
  opacity: ${(props) => (props.isRemoved ? "0.8" : "1")};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 10.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 100%;
    height: 100%;
  }
`;

export const Controls = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: block;
  }
`;

export const PrimaryControl = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: absolute;
    top: 0;
    left: 4px;
  }
`;

export const InfoControl = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: absolute;
    top: 0;
    right: 4px;
  }
`;

interface IWrapStyleProps {
  variant?: SubStatus;
}

interface IPitchElementStyleProps {
  isRemoved?: boolean;
}

export default Pitch;
