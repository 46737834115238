import styled from "styled-components/macro";
import { hiDPI } from "polished";
import StepSplash1x from "../img/home/step-splash.png";
import StepSplash2x from "../img/home/step-splash@2x.png";

interface SProps {
  lgSrc: string;
  mdSrc: string;
  smSrc: string;
}

const StyledTriangleCard = styled.div<SProps>`
  padding-top: 50%;
  background: ${({ theme }) => theme.colors.greyDarker4}
    url(${({ mdSrc }) => mdSrc}) top center no-repeat;
  background-size: contain;
  border-radius: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: relative;
    min-height: 220px;
    padding-top: 0;
    background-image: url(${({ lgSrc }) => lgSrc});
    background-size: cover;
    background-position: 50px -80px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-position: 50px -110px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    background-position: 50px -35px;
  }
`;

const Content = styled.div`
  padding: 2rem 2rem 0rem;
  background-color: ${({ theme }) => theme.colors.greyDarker4};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 6rem 2rem 0rem;
    position: relative;
    z-index: 11;
    width: 46%;
    background-color: transparent;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 2rem 2rem 0rem;
  }
`;

const StepSplashBg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0px;
  display: none;
  width: 340px;
  height: 220px;
  border-bottom-left-radius: 12px;
  background-image: url(${StepSplash1x});
  background-repeat: no-repeat;
  background-size: cover;

  ${hiDPI(2)} {
    background-image: url(${StepSplash2x});
  }
  z-index: 11;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: block;
  }
`;

const Svg = styled.svg`
  display: none;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    width: 70%;
    max-height: 100%;
    z-index: 10;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.25em;
  margin-bottom: ${({ theme }) => theme.space[3]};
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.impact};
`;

const Desc = styled.div`
  margin-top: 0;
  font-size: 16px;
  padding-bottom: ${({ theme }) => theme.space[4]};
  color: ${({ theme }) => theme.colors.black};
  line-height: normal;
`;

interface IProps {
  title: string;
  desc: string;
  lgSrc: string;
  mdSrc: string;
  smSrc: string;
}

const TriangleCard = ({ lgSrc, mdSrc, smSrc, title, desc }: IProps) => (
  <StyledTriangleCard lgSrc={lgSrc} mdSrc={mdSrc} smSrc={smSrc}>
    <StepSplashBg />
    <Svg
      viewBox="0 0 477 257"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <polygon
        id="Path"
        fill="#d9d8ed"
        points="476.699219 0 219.699219 257 0 257 0 0"
      />
    </Svg>
    <Content>
      <Title>{title}</Title>
      <Desc>{desc}</Desc>
    </Content>
  </StyledTriangleCard>
);

export default TriangleCard;
