import { Link } from "@reach/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import Button from "../Button";
import Copy from "../Copy";
import { ControlArrowRight } from "../icons/Arrows";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";

const Create: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Copy>
          <Title>
            {t("createLeagues.title", "Choose a league type to create")}
          </Title>
          <Box mb={4}>
            <SubHeading>
              {t("createLeagues.classic.title", "Classic scoring")}
            </SubHeading>
            <p>
              {t(
                "createLeagues.classic.p1",
                "In a league with classic scoring, teams are ranked based on their total points in the game. You can join or leave a league with classic scoring at any point during the season."
              )}
            </p>
            <Button as={Link} endIcon={<ControlArrowRight />} to="classic">
              {t("createLeagues.classic.button", "Create classic league & cup")}
            </Button>
          </Box>
          <Box mb={4}>
            <SubHeading>
              {t("createLeagues.h2H.title", "Head-to-Head scoring")}
            </SubHeading>
            <p>
              {t(
                "createLeagues.h2H.p0",
                "In a league with head-to-head scoring, every team plays a match against another team in the league each Round. The match result is based on the Round score of each team minus any transfer points spent preparing for the Round."
              )}
            </p>
            <p>
              {t(
                "createLeagues.h2H.p1",
                "The Head-to-Head schedule is generated at the start of the league's first Round. Once the schedule has been generated the league is locked and teams will not be able to join or leave."
              )}
            </p>
          </Box>
          <Button as={Link} endIcon={<ControlArrowRight />} to="h2h">
            {t("createLeagues.h2H.button", "Create head-to-head league")}
          </Button>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default Create;
