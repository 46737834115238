import { navigate } from "@reach/router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass";
import { ThunkDispatch } from "../../core/store";
import { bootstrap } from "../../core/store/bootstrap/thunks";
import { getPasswordChangeError } from "../../core/store/player/reducers";
import { passwordChange } from "../../core/store/player/thunks";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import { FieldFeedback, InputField } from "../FieldRenderers";
import { Main } from "../Layout";
import Title from "../Title";

interface IErrors {
  oldPassword: string;
  password: string;
}

const PasswordChange: React.FC = () => {
  const passwordChangeErrors = useSelector(getPasswordChangeError);
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<IErrors>({
    password: "",
    oldPassword: "",
  });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await reduxDispatch(
        passwordChange({
          old_password: oldPassword,
          password,
        })
      );
    } catch (e) {
      return;
    }
    await reduxDispatch(bootstrap());
    navigate("/");
  }

  // Errors which can be picked up by the UI
  const validate = () => {
    if (password.length <= 6) {
      errors.password = t(
        "passwordChange.hintPassword",
        "Minimum 6 characters"
      );
    }
    setErrors(errors);
  };

  const disabled = password.length < 6 || !oldPassword.length;

  return (
    <Main>
      <Box mx={2}>
        <Title>{t("passwordChange.title", "Change password")}</Title>
      </Box>
      {passwordChangeErrors && (
        <Alert type="error">
          {t("passwordChange.error", "Error updating password.")}
        </Alert>
      )}
      <Copy>
        <form onSubmit={handleSubmit}>
          <Box mt={2}>
            <InputField
              required
              id="ismOldPasswod"
              type="password"
              label={t("passwordChange.labelOldPassword", "Current password")}
              value={oldPassword}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setOldPassword(e.currentTarget.value)
              }
            />
          </Box>
          <Box mt={2}>
            <InputField
              required
              id="ismPassword"
              type="password"
              label={t("passwordChange.labelPassword", "New password")}
              value={password}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setPassword(e.currentTarget.value)
              }
              onBlur={validate}
              hint={t("passwordChange.hintPassword", "Minimum 6 characters")}
            />
          </Box>
          {errors.password && <FieldFeedback>{errors.password}</FieldFeedback>}
          <Box mt={2} mb={8}>
            <Button type="submit" disabled={disabled}>
              {t("passwordChange.button", "Update")}
            </Button>
          </Box>
        </form>
      </Copy>
    </Main>
  );
};

export default PasswordChange;
