import { range } from "lodash";
import { size } from "polished";
import * as React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, SavingState, ThunkDispatch } from "../../core/store";
import {
  cancelActiveChip,
  cancelProposedChip,
  proposeAvailableChip,
  restoreCancelledChip,
} from "../../core/store/chips/actions";
import {
  getActiveChip,
  getActiveOrProposedTeamChipName,
  getPotentialChips,
  teamChipsHaveChanged,
} from "../../core/store/chips/reducers";
import { IPotentialChip } from "../../core/store/chips/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElement, IElementsById } from "../../core/store/elements/types";
import { getEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IEntry, IPickLight } from "../../core/store/entries/types";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getFixturesForNextEventByTeam } from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import {
  changeCaptain,
  changeViceCaptain,
} from "../../core/store/my-team/actions";
import {
  getMyFormation,
  getMyPicksProposed,
  getMyTeamSavingState,
  hasMyTeamChanged,
  isMyTeamValid,
} from "../../core/store/my-team/reducers";
import {
  actionSubstitution,
  fetchMyTeam,
  saveMyTeam,
} from "../../core/store/my-team/thunks";
import { IPickProposed } from "../../core/store/my-team/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../core/store/player/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import {
  formatRawAsISO,
  formatRawAsLocalI18n,
} from "../../core/utils/datetime";
import { ReactComponent as ArrowRight } from "../../img/icons/arrow-right.svg";
import { ChipName, getAllChipDetails } from "../../utils/chips";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import DeadlineBar from "../DeadlineBar";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogManager from "../DialogManager";
import Entry from "../Entry";
import { FixturesForElement } from "../FixtureForElement";
import Fixtures from "../Fixtures";
import GoalVideos from "../GoalVideos";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import { ElementRow } from "../Pitch";
import DreamTeam from "../icons/DreamTeam";
// import ShirtSoonAlert from "../ShirtSoonAlert";
import { dateLocales } from "../../i18n";
import Title from "../Title";
import Tooltip, { TooltipLabel } from "../Tooltip";
import SaveBar from "../squad/SaveBar";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import BenchUnit from "./BenchUnit";
import MyTeamTable from "./MyTeamTable";
import PitchFormation from "./PitchFormation";

const TableWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const ChipList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.space[4]}  ${theme.space[2]}`};
  padding: ${({ theme }) =>
    `${theme.space[0]} ${theme.space[4]} ${theme.space[4]}`};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii[0]};
  list-style-type: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-direction: row;
    padding: ${({ theme }) => theme.space[2]};
    margin: ${({ theme }) =>
      `${theme.space[4]} ${theme.space[0]} ${theme.space[7]}`};
  }
`;

const ChipItem = styled.li`
  flex: 1;
  padding-right: ${({ theme }) => theme.space[1]};
  padding-left: ${({ theme }) => theme.space[1]};
`;

const Chip = styled.div`
  text-align: center;
`;

const ChipHeading = styled.h3`
  display: inline-block;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
`;

const ChipLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  padding: 0.9rem 2.7rem;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.greyDarker2};
  color: ${({ theme }) => theme.colors.black};

  :hover {
    text-decoration: none;
  }
`;

const ChipTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.baseSemiBold};
`;

const ChipLinkText = styled.div`
  color: ${({ theme }) => theme.colors.red};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: block;
  }
`;

const ChipLinkArrow = styled(ArrowRight)`
  ${size(10)};
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.space[2]};
  transform: translateY(-50%);
  fill: currentColor;
  color: ${({ theme }) => theme.colors.red};
  cursor: pointer;
`;

const renderPickValue =
  (initProps: {
    elementsById: IElementsById;
    fixturesForNextEventByTeam: IFixturesForEventByTeam;
    teamsById: ITeamsById;
  }) =>
  (pick: IPickLight) => {
    const element = initProps.elementsById[pick.element];
    return (
      <FixturesForElement
        fixtures={initProps.fixturesForNextEventByTeam[element.team]}
        element={element}
        teamsById={initProps.teamsById}
      />
    );
  };

const PlayerName = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.blueDarker2};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
`;

const PlayerActions = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 50%;
    margin: 0 auto;
  }
`;

interface IPropsFromState {
  activeChip: IPotentialChip | null;
  changed: boolean;
  chipInPlayName: string | null;
  chips: IPotentialChip[];
  chipsChanged: boolean;
  entry: IEntry | null;
  elementsById: IElementsById;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  formation: string;
  nextEvent: IEvent | null;
  picks: IPickProposed[];
  player: ILoggedInPlayer;
  savingState: SavingState;
  settings: ISettings;
  teamsById: ITeamsById;
  valid: boolean;
}

interface IPropsFromDispatch {
  cancelChip: (name: string) => void;
  deactivateChip: (name: string) => void;
  fetchEntrySummary: (entryId: number) => void;
  fetchMyTeam: () => void;
  makeCaptain: (elementId: number) => void;
  makeViceCaptain: (elementId: number) => void;
  proposeChip: (name: string) => void;
  restoreChip: (name: string) => void;
  save: () => void;
  showElementDialog: (elementId: number) => void;
  substitute: (elementId: number) => void;
}

type Props = WithTranslation & IPropsFromState & IPropsFromDispatch;

interface IState {
  pickForMenu: IPickProposed | null;
}

class MyTeam extends React.Component<Props, IState> {
  public state: IState = {
    pickForMenu: null,
  };

  public handleShowMenuForPickElement = (element: number) => {
    const matches = this.props.picks.filter((p) => p.element === element);
    if (matches.length) {
      this.setState({ pickForMenu: matches[0] });
    }
  };

  public handleHideMenuForPick = () => {
    this.setState({ pickForMenu: null });
  };

  public renderDreamTeam = (pick: IPickLight) =>
    this.props.elementsById[pick.element].in_dreamteam ? (
      <Link to="/dream-team/">
        <DreamTeam />
      </Link>
    ) : null;

  public action = (
    type: "substitute" | "makeCaptain" | "makeViceCaptain",
    element: number
  ) => {
    this.handleHideMenuForPick();
    this.props[type](element);
  };

  public showDialog = (element: IElement) => {
    this.props.showElementDialog(element.id);
    this.handleHideMenuForPick();
  };

  public renderMenu() {
    const pick = this.state.pickForMenu;
    if (!pick) {
      return null;
    }
    const element = this.props.elementsById[pick.element];
    const status = pick.subStatus;
    const startMax = this.props.settings.squad_squadplay;
    const t = this.props.t;
    return (
      <Dialog closeDialog={this.handleHideMenuForPick}>
        <Dialog.Header closeDialog={this.handleHideMenuForPick}>
          <PlayerName>
            {`${element.first_name} ${element.second_name}`}
          </PlayerName>
        </Dialog.Header>
        <Dialog.Body>
          <PlayerActions>
            {status.match(/^(target|)$/) && (
              <DialogButtonItem>
                <Button
                  onClick={() => this.action("substitute", pick.element)}
                  width={1}
                >
                  {t("myTeam.menuDialog.switch", "Switch")}
                </Button>
              </DialogButtonItem>
            )}
            {status === "instigator" && (
              <DialogButtonItem>
                <Button
                  onClick={() => this.action("substitute", pick.element)}
                  width={1}
                >
                  {t("myTeam.menuDialog.cancel", "Cancel")}
                </Button>
              </DialogButtonItem>
            )}
            {!status && pick.position <= startMax && !pick.is_captain && (
              <DialogButtonItem>
                <Button
                  onClick={() => this.action("makeCaptain", pick.element)}
                  width={1}
                >
                  {t("myTeam.menuDialog.makeCaptain", "Make Captain")}
                </Button>
              </DialogButtonItem>
            )}
            {!status && pick.position <= startMax && !pick.is_vice_captain && (
              <DialogButtonItem>
                <Button
                  onClick={() => this.action("makeViceCaptain", pick.element)}
                  width={1}
                >
                  {t("myTeam.menuDialog.makeViceCaptain", "Make Vice Captain")}
                </Button>
              </DialogButtonItem>
            )}
            <DialogButtonItem>
              <Button onClick={() => this.showDialog(element)} width={1}>
                {t("myTeam.menuDialog.viewInfo", "View Information")}
              </Button>
            </DialogButtonItem>
          </PlayerActions>
        </Dialog.Body>
      </Dialog>
    );
  }

  public componentDidMount() {
    this.props.fetchMyTeam();
    this.props.fetchEntrySummary(this.props.player.entry);
  }

  public renderChipAction(c: IPotentialChip) {
    const {
      cancelChip,
      chipInPlayName,
      deactivateChip,
      entry,
      proposeChip,
      restoreChip,
      t,
    } = this.props;
    switch (c.status_for_entry) {
      case "available":
      case "unavailable":
        return (
          <Button
            onClick={() => proposeChip(c.name)}
            disabled={Boolean(chipInPlayName)}
            width={1}
            variant="secondary"
          >
            {t("myTeam.play", "Play")}
          </Button>
        );
      case "proposed":
        return (
          <Button onClick={() => cancelChip(c.name)} width={1}>
            {t("myTeam.cancel", "Cancel")}
          </Button>
        );
      case "active":
        return (
          <Button onClick={() => deactivateChip(c.name)} width={1}>
            {t("myTeam.cancel", "Cancel")}
          </Button>
        );
      case "cancelled":
        return (
          <Button
            onClick={() => restoreChip(c.name)}
            disabled={Boolean(chipInPlayName)}
            width={1}
          >
            {t("myTeam.restore", "Restore")}
          </Button>
        );
      case "played":
        if (!entry) {
          return (
            <>
              <div>{t("myTeam.played", "Played")}</div>
              <div>{`${t("myTeam.round", "Round")} ${
                c.played_by_entry[0]
              }`}</div>
            </>
          );
        }
        return (
          <ChipLink to={`/entry/${entry.id}/event/${c.played_by_entry[0]}`}>
            <ChipTextWrap>
              <div>{`${t("myTeam.played", "Played")} ${t(
                "myTeam.round",
                "Round"
              )} ${c.played_by_entry[0]} `}</div>
              <ChipLinkText>{t("myTeam.points", "Points")}</ChipLinkText>
              <ChipLinkArrow />
            </ChipTextWrap>
          </ChipLink>
        );
      default:
        return "";
    }
  }

  public renderRichChipAction(c: IPotentialChip) {
    const { chipInPlayName, entry, t } = this.props;
    switch (c.status_for_entry) {
      case "available":
      case "unavailable":
        return (
          <DialogManager
            render={(showDialog, handleShow, handleHide) => (
              <>
                <Button
                  onClick={handleShow}
                  disabled={Boolean(chipInPlayName)}
                  width={1}
                  variant="secondary"
                >
                  {t("myTeam.play", "Play")}
                </Button>
                {showDialog && (
                  <Dialog closeDialog={handleHide}>
                    <Dialog.Header closeDialog={handleHide}>
                      {t("myTeam.chips.rich.name", "Rich Uncle")}
                    </Dialog.Header>
                    <Dialog.Body isPadded={true}>
                      <Copy>
                        <p>
                          {t(
                            "myTeam.chips.rich.dialog.p1",
                            "The Rich Uncle chip is played when confirming your transfers and can be used once a season."
                          )}
                        </p>
                        <p>
                          {t(
                            "myTeam.chips.rich.dialog.p2",
                            "It allows you to make unlimited free transfers with an unlimited budget for a single round. At the next deadline, your squad is returned to how it was at the start of the round."
                          )}
                        </p>
                        <p>
                          <Trans i18nKey="myTeam.chips.rich.dialog.p3">
                            Please note: Rich Uncle{" "}
                            <strong>cannot be cancelled </strong> once
                            confirmed.
                          </Trans>
                        </p>
                      </Copy>
                    </Dialog.Body>
                  </Dialog>
                )}
              </>
            )}
          />
        );
      case "active":
        return <Button width={1}>{t("myTeam.active", "Active")}</Button>;
      case "played":
        if (!entry) {
          return (
            <>
              <div>{t("myTeam.active", "Active")}</div>
              <div>{`${t("myTeam.round", "Round")} ${
                c.played_by_entry[0]
              }`}</div>
            </>
          );
        }
        return (
          <ChipLink to={`/entry/${entry.id}/event/${c.played_by_entry[0]}`}>
            <div>
              <div>{t("myTeam.played", "Played")}</div>
              <div>{`${t("myTeam.round", "Round")} ${
                c.played_by_entry[0]
              }`}</div>
              <ChipLinkArrow />
            </div>
          </ChipLink>
        );
      default:
        return "";
    }
  }

  public render() {
    const {
      activeChip,
      changed,
      chipInPlayName,
      chips,
      chipsChanged,
      elementsById,
      entry,
      fixturesForNextEventByTeam,
      formation,
      i18n,
      nextEvent,
      picks,
      save,
      savingState,
      settings,
      t,
      teamsById,
      valid,
    } = this.props;
    if (!entry || !picks.length) {
      return null;
    }
    // Create a new function on each render as fixtures could have changed and
    // need to ensure a render of connected subcomponents
    const _renderPickValue = renderPickValue({
      elementsById,
      teamsById,
      fixturesForNextEventByTeam,
    });
    const benchHeading: Record<string, React.ReactNode> = {
      11: (
        <Tooltip content="Goalkeeper">
          <TooltipLabel>GK</TooltipLabel>
        </Tooltip>
      ),
      12: "1",
      13: "2",
      14: "3",
    };

    const richChip = chips.filter((c) => c.name === "rich")[0];
    const chipDetails = getAllChipDetails(t);
    return (
      <>
        <Wrapper>
          <Main>
            <Box mx={2}>
              <Title>{t("myTeam.title", "My Team")}</Title>
            </Box>
            {nextEvent && (
              <DeadlineBar
                deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
                deadlineLocal={formatRawAsLocalI18n(
                  nextEvent.deadline_time,
                  dateLocales[i18n.language]
                )}
                headingText={nextEvent.name}
              />
            )}
            <Box m={2}>
              <Alert type="info">
                {t(
                  "myTeam.alerts.captain",
                  "To change your captain use the menu which appears when clicking on a player's shirt."
                )}
              </Alert>
            </Box>
            <Box pt={4}>
              <Box mx={2} mb={2}>
                <Tabs centered>
                  <TabPanel
                    label={t("myTeam.pitchView", "Pitch View")}
                    link="pitch"
                  >
                    <Box bg="primary" mx={-2} pt={4}>
                      <PitchFormation
                        chipName={chipInPlayName}
                        formation={formation}
                        picks={picks}
                        renderDreamTeam={this.renderDreamTeam}
                        renderElementMenu={this.handleShowMenuForPickElement}
                        renderPickValue={_renderPickValue}
                      />
                      <ElementRow>
                        {range(
                          settings.squad_squadplay,
                          settings.squad_squadsize
                        ).map((i) => (
                          <BenchUnit
                            key={i}
                            chipName={chipInPlayName}
                            heading={benchHeading[i]}
                            pick={picks[i]}
                            renderElementMenu={() =>
                              this.handleShowMenuForPickElement(
                                picks[i].element
                              )
                            }
                            renderDreamTeam={this.renderDreamTeam}
                            renderPickValue={_renderPickValue}
                          />
                        ))}
                      </ElementRow>
                    </Box>
                  </TabPanel>
                  <TabPanel
                    label={t("myTeam.listView", "List View")}
                    link="list"
                  >
                    <Box mb={2}>
                      <TableWrap>
                        <MyTeamTable
                          title={t("myTeam.starters", "Starters")}
                          picks={picks.slice(0, settings.squad_squadplay)}
                          renderElementMenu={this.handleShowMenuForPickElement}
                        />
                      </TableWrap>
                      <TableWrap>
                        <MyTeamTable
                          title={t("myTeam.substitutes", "Substitutes")}
                          picks={picks.slice(settings.squad_squadplay)}
                          renderElementMenu={this.handleShowMenuForPickElement}
                        />
                      </TableWrap>
                    </Box>
                  </TabPanel>
                </Tabs>
              </Box>
            </Box>
            {/* <ShirtSoonAlert /> */}
            {activeChip && activeChip.chip_type === "transfer" ? (
              <Box my={2}>
                <Alert type="info">
                  <p>
                    {t("myTeam.alerts.transferChip", "{{ chipName }} Active", {
                      chipName: chipDetails[activeChip.name as ChipName].name,
                    })}
                  </p>
                </Alert>
              </Box>
            ) : (
              <ChipList>
                {chips
                  .filter((c) => ["frush", "2capt"].indexOf(c.name) > -1)
                  .map((c) => (
                    <ChipItem key={c.name}>
                      <Chip>
                        <Tooltip
                          content={chipDetails[c.name as ChipName].description}
                        >
                          <ChipHeading>
                            {chipDetails[c.name as ChipName].name}
                          </ChipHeading>
                        </Tooltip>
                        {this.renderChipAction(c)}
                      </Chip>
                    </ChipItem>
                  ))}
                {richChip && (
                  <ChipItem>
                    <Chip>
                      <Tooltip
                        content={
                          chipDetails[richChip.name as ChipName].description
                        }
                      >
                        <ChipHeading>
                          {chipDetails[richChip.name as ChipName].name}
                        </ChipHeading>
                      </Tooltip>
                      {this.renderRichChipAction(richChip)}
                    </Chip>
                  </ChipItem>
                )}
              </ChipList>
            )}
            {savingState === "saved" ? (
              <Box my={2}>
                <Alert type="success">
                  {t("myTeam.alerts.teamSaved", "Your team has been saved.")}
                </Alert>
              </Box>
            ) : (
              <SaveBar>
                <Button
                  onClick={save}
                  disabled={
                    (!changed && !chipsChanged) ||
                    !valid ||
                    savingState === "saving"
                  }
                  width={1}
                >
                  {t("myTeam.saveTeam", "Save Your Team")}
                </Button>
              </SaveBar>
            )}
            {this.renderMenu()}
            <Fixtures />
            <GoalVideos />
          </Main>
          <Secondary>
            <Entry entryId={entry.id} />
          </Secondary>
        </Wrapper>
      </>
    );
  }
}

export { MyTeam as MyTeamTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  return {
    activeChip: getActiveChip(state),
    chipInPlayName: getActiveOrProposedTeamChipName(state),
    changed: hasMyTeamChanged(state),
    chips: getPotentialChips(state),
    chipsChanged: teamChipsHaveChanged(state),
    elementsById: getElementsById(state),
    entry: getEntry(state, player.entry),
    fixturesForNextEventByTeam: getFixturesForNextEventByTeam(state),
    formation: getMyFormation(state),
    nextEvent: getNextEvent(state),
    picks: getMyPicksProposed(state),
    player,
    savingState: getMyTeamSavingState(state),
    settings: getSettings(state) as ISettings,
    teamsById: getTeamsById(state),
    valid: isMyTeamValid(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  cancelChip: (name) => dispatch(cancelProposedChip(name)),
  deactivateChip: (name) => dispatch(cancelActiveChip(name)),
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchMyTeam: () => dispatch(fetchMyTeam()),
  makeCaptain: (elementId) => dispatch(changeCaptain(elementId)),
  makeViceCaptain: (elementId) => dispatch(changeViceCaptain(elementId)),
  proposeChip: (name) => dispatch(proposeAvailableChip(name)),
  restoreChip: (name) => dispatch(restoreCancelledChip(name)),
  save: () => dispatch(saveMyTeam()),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  substitute: (elementId) => dispatch(actionSubstitution(elementId)),
});
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyTeam)
);
