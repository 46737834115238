import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IH2HMatch } from "../../core/store/leagues/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { ReactComponent as CupBase } from "../../img/icons/cup.svg";
import { getShortNameFromId } from "../../utils/events";
import Alert from "../Alert";
import Link from "../Link";

const CupWinner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCup = styled(CupBase)`
  color: #dbaf13;
  margin-right: 0.6rem;
`;

interface IStyledMatchRow {
  inProgress: boolean;
  mine: boolean;
}

interface IMatchesScore {
  inProgress: boolean;
}

const MatchesScore = styled.div<IMatchesScore>`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.greyDarker3};
  border-radius: ${({ theme }) => theme.radii[0]};
  background-color: white;
  color: ${({ theme }) => theme.colors.greyDarker3};

  ${(props) =>
    props.inProgress &&
    css`
      border: 0;
      background-color: ${({ theme }) => theme.colors.blueDarker2};
      color: white;

      td {
        > a {
          color: inherit;
        }
      }
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 80%;
    margin: auto;
  }
`;

const MatchesScoreTeam = styled.span`
  width: 50%;
  padding: 0.2rem;
  font-family: ${({ theme }) => theme.fonts.baseSemiBold};
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;

  :first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.greyDarker3};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${({ theme }) => theme.space[1]};
    font-size: 1.8rem;
  }
`;

const StyledStatusRow = styled.tr`
  td {
    padding-top: 0;
    border-top: 0 !important;
  }
`;

const StyledMatchRow = styled.tr<IStyledMatchRow>`
  ~ ${StyledStatusRow} td {
    border-top: 0;
  }

  ${(props) =>
    props.mine &&
    css`
      td {
        background-color: ${({ theme }) => theme.colors.primary};
        color: white;
        > a {
          color: inherit;
        }
      }
    `}
`;

const MatchesEntryStyles = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const MatchesEntry1 = styled.td`
  ${MatchesEntryStyles}
  text-align: right;
`;

const MatchesEntry2 = styled.td`
  ${MatchesEntryStyles}
`;

const StatusCell = styled.td`
  white-space: normal;
  text-align: center;
`;

const MatchesScoreWrap = styled.td`
  text-align: center;
  white-space: nowrap;
`;

const ByeScore = styled.div`
  display: inline-block;
  padding: 0.3rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.greyDarker3};
  border-radius: ${({ theme }) => theme.radii[0]};
  font-family: ${({ theme }) => theme.fonts.baseSemiBold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0.4rem 1.7rem;
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }
`;

const KONameRow = styled.tr`
  td {
    padding-bottom: 0;
  }
  + tr td {
    border-top: 0;
  }
`;

const KONameCell = styled.td`
  text-align: center;
`;

interface IStyledKONameCell {
  isFinal: boolean;
}

const KOName = styled.span<IStyledKONameCell>`
  font-size: 1.1rem;

  ${(props) =>
    props.isFinal &&
    css`
      font-family: ${({ theme }) => theme.fonts.baseSemiBold};
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 1.3rem;
  }
`;

const WinnerStatus: React.FC<{ isCup: boolean; match: IH2HMatch }> = ({
  isCup,
  match,
}) => {
  const { t } = useTranslation();
  if (!match.winner) {
    return null;
  }
  const winnerName =
    match.winner === match.entry_1_entry
      ? match.entry_1_name
      : match.entry_2_name;

  return (
    <>
      {match.tiebreak && (
        <StatusRow>
          <Alert type="info">
            {t("h2hMatch.tie", "Tie-break - {{winnerName}} progressed", {
              winnerName: winnerName,
            })}{" "}
            {match.tiebreak === "goals_scored" &&
              t("h2hMatch.tie1", "on most goals scored")}
            {match.tiebreak === "goals_conceded" &&
              t("h2hMatch.tie2", "on fewest goals conceded")}
            {match.tiebreak === "default" &&
              t("h2hMatch.tie3", "by virtual coin toss")}
          </Alert>
        </StatusRow>
      )}
      {isCup && match.event === 30 && (
        <StatusRow>
          <CupWinner>
            <StyledCup />
            <span>{winnerName}</span>
          </CupWinner>
        </StatusRow>
      )}
    </>
  );
};

const StatusRow: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <StyledStatusRow className="ism-status-row">
    <td>&nbsp;</td>
    <StatusCell colSpan={3}>{children}</StatusCell>
  </StyledStatusRow>
);

interface IH2HMatchRow {
  selectedEntry: number;
  isCup: boolean;
  showEvent?: boolean;
  started?: boolean;
  match: IH2HMatch;
  isAllMine: boolean;
}

const H2HMatch: React.FC<IH2HMatchRow> = ({
  selectedEntry,
  showEvent = false,
  started = false,
  match,
  isAllMine = false,
  isCup,
}) => {
  const { t } = useTranslation();
  const player = useSelector(getPlayerData);
  const now = useSelector(getCurrentEvent);
  const inProgress = now?.id === match.event && !match.winner ? true : false;
  return (
    <>
      {match.knockout_name ? (
        <KONameRow>
          <td>&nbsp;</td>
          <KONameCell colSpan={3}>
            <KOName isFinal={match.event === 30}>{match.knockout_name}</KOName>
          </KONameCell>
        </KONameRow>
      ) : null}
      <StyledMatchRow
        inProgress={inProgress}
        mine={
          !isAllMine &&
          player !== null &&
          (player.entry === match.entry_1_entry ||
            player.entry === match.entry_2_entry)
        }
      >
        {showEvent && <td>{getShortNameFromId(match.event, true)}</td>}
        {/* Lots of duplication here but can't think of a clean way around it */}
        {selectedEntry === match.entry_2_entry ? (
          <>
            <MatchesEntry1>
              {match.entry_2_entry && started ? (
                <Link to={`/entry/${match.entry_2_entry}/event/${match.event}`}>
                  <strong>{match.entry_2_name}</strong>
                  <br />
                  {match.entry_2_player_name}
                </Link>
              ) : (
                <Link to={`/entry/${match.entry_2_entry}/history`}>
                  <strong>{match.entry_2_name}</strong>
                  <br />
                  {match.entry_2_player_name}
                </Link>
              )}
            </MatchesEntry1>
            <MatchesScoreWrap>
              {match.is_bye ? (
                <ByeScore>{t("h2hEventFilter.score", "N/A")}</ByeScore>
              ) : (
                <MatchesScore inProgress={inProgress}>
                  {started ? (
                    <>
                      <MatchesScoreTeam>
                        {match.entry_2_points}
                      </MatchesScoreTeam>
                      <MatchesScoreTeam>
                        {match.entry_1_points}
                      </MatchesScoreTeam>
                    </>
                  ) : (
                    <>
                      <MatchesScoreTeam>-</MatchesScoreTeam>
                      <MatchesScoreTeam>-</MatchesScoreTeam>
                    </>
                  )}
                </MatchesScore>
              )}
            </MatchesScoreWrap>
            <MatchesEntry2>
              {match.entry_1_entry ? (
                started ? (
                  <Link
                    to={`/entry/${match.entry_1_entry}/event/${match.event}`}
                  >
                    <strong>{match.entry_1_name}</strong>
                    <br />
                    {match.entry_1_player_name}
                  </Link>
                ) : (
                  <Link to={`/entry/${match.entry_1_entry}/history`}>
                    <strong>{match.entry_1_name}</strong>
                    <br />
                    {match.entry_1_player_name}
                  </Link>
                )
              ) : (
                <>&nbsp;</>
              )}
            </MatchesEntry2>
          </>
        ) : (
          <>
            <MatchesEntry1>
              {match.entry_1_entry && started ? (
                <Link to={`/entry/${match.entry_1_entry}/event/${match.event}`}>
                  <strong>{match.entry_1_name}</strong>
                  <br />
                  {match.entry_1_player_name}
                </Link>
              ) : (
                <Link to={`/entry/${match.entry_1_entry}/history`}>
                  <strong>{match.entry_1_name}</strong>
                  <br />
                  {match.entry_1_player_name}
                </Link>
              )}
            </MatchesEntry1>
            <MatchesScoreWrap>
              {match.is_bye ? (
                <ByeScore>{t("h2hEventFilter.score2", "N/A")}</ByeScore>
              ) : (
                <MatchesScore inProgress={inProgress}>
                  {started ? (
                    <>
                      <MatchesScoreTeam>
                        {match.entry_1_points}
                      </MatchesScoreTeam>
                      <MatchesScoreTeam>
                        {match.entry_2_points}
                      </MatchesScoreTeam>
                    </>
                  ) : (
                    <>
                      <MatchesScoreTeam>-</MatchesScoreTeam>
                      <MatchesScoreTeam>-</MatchesScoreTeam>
                    </>
                  )}
                </MatchesScore>
              )}
            </MatchesScoreWrap>
            <MatchesEntry2>
              {match.entry_2_entry ? (
                started ? (
                  <Link
                    to={`/entry/${match.entry_2_entry}/event/${match.event}`}
                  >
                    <strong>{match.entry_2_name}</strong>
                    <br />
                    {match.entry_2_player_name}
                  </Link>
                ) : (
                  <Link to={`/entry/${match.entry_2_entry}/history`}>
                    <strong>{match.entry_2_name}</strong>
                    <br />
                    {match.entry_2_player_name}
                  </Link>
                )
              ) : (
                <>&nbsp;</>
              )}
            </MatchesEntry2>
          </>
        )}
      </StyledMatchRow>
      {match.is_bye && (
        <StatusRow>
          <Alert type="info">
            {t(
              "h2hEventFilter.isbye",
              "{{matchEntry}} progressed with a bye due to their Round {{matchEvent}} score",
              {
                matchEntry: match.entry_1_name,
                matchEvent: match.event - 1,
              }
            )}
          </Alert>
        </StatusRow>
      )}
      {match.winner && <WinnerStatus match={match} isCup={isCup} />}
    </>
  );
};

export default H2HMatch;
