import styled from "styled-components/macro";

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 1.25em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default Title;
