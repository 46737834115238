import styled from "styled-components/macro";

interface IHeadingProps {
  variant?: string;
}

const SubHeading = styled.h3<IHeadingProps>`
  margin: ${({ theme }) => theme.space[3]} 0;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  color: ${({ theme, variant }) =>
    variant === "light" ? theme.colors.white : theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.impact};
`;

export default SubHeading;
