import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import configureStore from "./core/configureStore";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://f138bb7f42474ff7a5032f7d338b4135@sentry.io/2243032",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    whitelistUrls: [/eliteserien\.no/, /ismgames\.com/],
    ignoreErrors: [],
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
