import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { INavLink } from "../../core/hooks/types";
import usePriorityNav from "../../core/hooks/usePriorityNav";
import { RootState } from "../../core/store";
import { getEntry } from "../../core/store/entries/reducers";
import {
  getCurrentEvent,
  getNextEvent,
} from "../../core/store/events/reducers";
import { getServerError } from "../../core/store/global/reducers";
import { getPlayerData } from "../../core/store/player/reducers";
import Dropdown from "./Dropdown";
import ListItem from "./ListItem";

const StyledNav = styled.nav`
  white-space: nowrap;
`;

const StyledUL = styled.ul`
  vertical-align: middle;
  font-size: 0;
  padding: 0;
`;

const NavigationLinks: React.FC<{ links: INavLink[] }> = ({ links }) => {
  const { t } = useTranslation();
  const [measuredRef, visibleLinks, nonVisibleLinks] = usePriorityNav({
    links,
    offset: 50,
  });
  return (
    <StyledNav role="navigation" className="ism-nav">
      <StyledUL>
        {visibleLinks.map((link) => (
          <ListItem {...link} ref={measuredRef} key={link.text} />
        ))}
        {!!nonVisibleLinks.length && (
          <Dropdown
            text={t("navigation.more", "More")}
            links={nonVisibleLinks as INavLink[]}
          />
        )}
      </StyledUL>
    </StyledNav>
  );
};

const mapStateToProps = (state: RootState) => {
  const player = getPlayerData(state);
  const currentEvent = getCurrentEvent(state);
  const nextEvent = getNextEvent(state);
  const serverError = getServerError(state);
  const entry = player && player.entry ? getEntry(state, player.entry) : null;
  return {
    player,
    currentEvent,
    nextEvent,
    serverError,
    entry,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Navigation: React.FC<PropsFromRedux> = ({
  currentEvent,
  entry,
  nextEvent,
  player,
  serverError,
}) => {
  const { t } = useTranslation();
  let links: INavLink[] = [];
  const useRouter = !serverError;
  if (player && player.entry) {
    // Logged in with entry
    links = [
      {
        useRouter,
        href: "/",
        text: currentEvent
          ? t("navigation.status", "Status")
          : t("navigation.home", "Home"),
      },
    ];
    if (nextEvent) {
      links.push({
        useRouter,
        href: "/my-team",
        text: t("navigation.myTeam", "My Team"),
      });
    }
    // If the entry has started ...
    if (currentEvent && entry && entry.started_event <= currentEvent.id) {
      links.push({
        useRouter,
        href: `/entry/${player.entry}/event/${currentEvent.id}`,
        text: t("navigation.points", "Points"),
      });
    }
    links.push(
      {
        useRouter,
        href: "/transfers",
        text: t("navigation.transfers", "Transfers"),
      },
      {
        useRouter,
        href: "/leagues",
        text: t("navigation.leagues", "Leagues"),
      },
      {
        useRouter,
        href: "/fixtures",
        text: t("navigation.fixtures", "Fixtures"),
      },
      {
        useRouter,
        href: "/statistics",
        text: t("navigation.statistics", "Statistics"),
      },
      { useRouter, href: "/the-scout", text: t("navigation.scout", "Scout") },
      {
        useRouter,
        href: "/prizes",
        text: t("navigation.prizes", "Prizes"),
      },
      {
        useRouter,
        href: "/help",
        text: t("navigation.help", "Help"),
      },
      {
        useRouter,
        href: "/player/logout",
        text: t("navigation.signOut", "Sign out"),
      }
    );
  } else if (player) {
    // Logged in, no entry
    links = [
      { useRouter, href: "/", text: t("navigation.home", "Home") },
      {
        useRouter,
        href: "/squad-selection",
        text: t("navigation.squadSelection", "Squad Selection"),
      },
      { useRouter, href: "/help", text: t("navigation.help", "Help") },
      {
        useRouter,
        href: "/player/logout",
        text: t("navigation.signOut", "Sign out"),
      },
    ];
  } else {
    // Logged Out
    links = [
      { useRouter, href: "/", text: t("navigation.home", "Home") },
      {
        useRouter,
        href: "/prizes",
        text: t("navigation.prizes", "Prizes"),
      },
      { useRouter, href: "/the-scout", text: t("navigation.scout", "Scout") },
      { useRouter, href: "/help", text: t("navigation.help", "Help") },
      {
        useRouter,
        href: "/statistics",
        text: t("navigation.statistics", "Statistics"),
      },
    ];
  }
  return <NavigationLinks links={links} />;
};

export { Navigation as NavigationTest };

export default connector(Navigation);
