import { Link } from "@reach/router";
import React from "react";
import styled, { css } from "styled-components/macro";
import { INavLink } from "../../core/hooks/types";
import useDocumentEventHandler from "../../core/hooks/useDocumentEventHandler";
import "./Dropdown.css";
import { ReactComponent as ArrowDown } from "../../img/icons/arrow-down.svg";

const StyledDropdown = styled.span`
  display: inline-block;
  position: relative;
`;

const MoreButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.9rem 1.8rem;
  border: 0;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.impactMedium};
  line-height: 1;
  transition: all 0.2s;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  :focus,
  :hover {
    background-color: ${({ theme }) => theme.colors.redDarker};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  :hover,
  :focus {
    fill: ${({ theme }) => theme.colors.white};
  }
  margin-left: 8px;
`;

const NavLink = css`
  display: block;
  padding: 0.9rem 1.8rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;

  :focus,
  :hover {
    background-color: ${({ theme }) => theme.colors.redDarker};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const NavReachLink = styled(Link)`
  ${NavLink}
`;

const NavHyperLink = styled.a`
  ${NavLink}
`;

type Props = {
  text: string;
  links: INavLink[];
};

const Dropdown: React.FunctionComponent<Props> = ({ text, links }) => {
  const [open, setOpen] = React.useState(false);
  useDocumentEventHandler({ event: "click", handler: () => setOpen(false) });
  const toggleOpen = (e: React.SyntheticEvent) => {
    e.nativeEvent.stopImmediatePropagation();
    setOpen((open) => !open);
  };

  return (
    <StyledDropdown aria-haspopup="true">
      <MoreButton aria-controls="menu" type="button" onClick={toggleOpen}>
        {text}
        <StyledArrowDown />
      </MoreButton>
      <ul aria-hidden={!open} className={`list ${open && "show"}`}>
        {links.map((link) => (
          <li key={link.text}>
            {link.useRouter ? (
              <NavReachLink to={link.href}>{link.text}</NavReachLink>
            ) : (
              <NavHyperLink
                href={link.href}
                rel={link.rel}
                target={link.target}
              >
                {link.text}
              </NavHyperLink>
            )}
          </li>
        ))}
      </ul>
    </StyledDropdown>
  );
};

export default Dropdown;
