import React from "react";
import styled, { css } from "styled-components/macro";
import { IElement } from "../../core/store/elements/types";

export const ElementName = styled.div<IStyledProps>`
  padding: 0.1rem 0.2rem;
  background-color: ${({ theme }) => theme.colors.blueDarker3};
  color: ${({ theme }) => theme.colors.white};
  color: white;
  font-size: 1rem;
  font-weight: 800;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: 1.6rem;

  ${(props) =>
    props.element.news &&
    props.element.chance_of_playing_next_round !== null &&
    css`
      background-color: ${props.theme.colors.elementStatus[
        props.element.chance_of_playing_next_round
      ].bg};
      color: ${props.theme.colors.elementStatus[
        props.element.chance_of_playing_next_round
      ].color};
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;

export const ElementValue = styled.div`
  padding: 0.2rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blueDarker3};
  font-size: 1.2rem;
  font-weight: 800;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: 1.6rem;
`;

interface IStyledProps {
  element: IElement;
}

interface IProps {
  value: React.ReactNode;
}

type Props = IStyledProps & IProps;

const StatusPitchElementData: React.FC<Props> = ({ element, value }) => (
  <div>
    <ElementName element={element}>{element.web_name}</ElementName>
    <ElementValue>{value}</ElementValue>
  </div>
);

export default StatusPitchElementData;
